import { takeLatest, call, put } from "redux-saga/effects";
import { common_post, HLog, localRemove, localSet } from "../../helpers";
import { apis, keys } from "../../constants";
import AxiosService from "../../common/AxiosService";
import { doInitMasterData, setAppProperties ,refetchMasterStandard} from "../slices/appSlice";
import { uniqueRollList } from "../../utils/common";
import { uniq } from "lodash";

export function* watchAppSaga() {
  yield takeLatest(doInitMasterData.type, handleLoadSteelTypeMaster);
  yield takeLatest(doInitMasterData.type, handleLoadMasterShift);
  yield takeLatest(doInitMasterData.type, handleLoadMasterTeam);
  yield takeLatest(doInitMasterData.type, handleLoadMasterCustomerType);
  yield takeLatest(doInitMasterData.type, handleLoadListEmailUser);
  yield takeLatest(doInitMasterData.type, handleLoadMasterRollManufacture);
  yield takeLatest(doInitMasterData.type, handleLoadMasterStandard);
  yield takeLatest(refetchMasterStandard.type, handleLoadMasterStandard);
  yield takeLatest(doInitMasterData.type, handleLoadMasterSplitMachine);
}

export function* handleLoadSteelTypeMaster(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_SteelType, {})

    HLog("handleLoadSteelTypeMaster response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      steelTypeMaster:   data.map(item  => ({ value: item.typeAcronym , label: item.steelTypeName }))
    }))
    } else {
      yield put( setAppProperties({
        steelTypeMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadSteelTypeMaster Error", error);
  }
}

export function* handleLoadMasterShift(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_Shift, {})

    HLog("handleLoadMasterShift response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      shiftMaster:  [{ value: '' , label: 'Tất cả' }].concat(data.map(item  => ({ value: item.shiftWorkName , label: item.shiftWorkName })))
    }))
    } else {
      yield put( setAppProperties({
        shiftMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterShift Error", error);
  }
}

export function* handleLoadMasterTeam(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_Team, {})

    HLog("handleLoadMasterTeam response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      teamMaster: [{ value: '' , label: 'Tất cả' }].concat(data.map(item  => ({ value: item.teamWorkName , label: item.teamWorkName })))
    }))
    } else {
      yield put( setAppProperties({
        teamMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterTeam Error", error);
  }
}

export function* handleLoadMasterCustomerType(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_CustomerType, {})

    HLog("handleLoadMasterCustomerType response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      customerMaster: [{ value: '' , label: '' }].concat(data.map(item  => ({ value: item.nameAcronym , label: item.name })))
    }))
    } else {
      yield put( setAppProperties({
        customerMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterCustomerType Error", error);
  }
}

export function* handleLoadListEmailUser(action) {
  try {
    const response = yield AxiosService.get(apis.Url_Api_Get_ListEmailUser)

    HLog("handLoadListEmailUser response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      listEmailUser: data
    }))
    } else {
      yield put( setAppProperties({
        listEmailUser: []
      }))
    }
  } catch (error) {
    HLog("handLoadListEmailUser Error", error);
  }
}

export function* handleLoadMasterRollManufacture(action) {
  try {
    const response = yield AxiosService.get(`${apis.Url_Api_Get_MasterTape_RollManufacture}?isForTape=false`);
    const response2 = yield AxiosService.get(`${apis.Url_Api_Get_MasterTape_RollManufacture}?includeImported=true`);
    const { data, status } = response.data;
    const {data: data2, status: status2} = response2.data;
    
    // master dùng cho option chọn cuộn
    var listRollManufacture = !!data && status == 200 ? uniqueRollList(data).map(item => ({...item, label: item.nsmvCoilId, value: item.id })) : [];

    // master dùng cho fill dữ liệu
    var listRollManufactureIncludeImported = !!data2 && status2 == 200 ? uniqueRollList(data2) : [];

    // console.log("List roll 1", listRollManufacture);
    // console.log("List roll2", listRollManufactureIncludeImported)
    yield put( setAppProperties({
      listRollManufacture,listRollManufactureIncludeImported
    }))
  } catch (error) {
    HLog("handleLoadMasterRollManufacture Error", error);
  }
}

export function* handleLoadMasterStandard(action) {
  try {
    const response = yield AxiosService.get(apis.Url_Api_Get_MasterTape_Standard)

    HLog("handleLoadMasterStandard response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
      const dataNormal = data.filter(i => i.productFlg == "0");
      const dataSpecial = data.filter(i => i.productFlg == "1");

      var listMasterStandard = dataNormal.map(item => ({...item, value: item.typeProduct, label: item.typeProduct}));
      var listMasterStandardSpecial = dataSpecial.map(item => ({...item, value: item.typeProduct, label: item.typeProduct}));
      var lstStandard = uniq(dataNormal.map(i => i.lstBody.map(ii => ii.specificSize)).flat());
      var lstStandardSpecial = uniq(dataSpecial.map(i => i.lstBody.map(ii => ii.specificSize)).flat());

     yield put( setAppProperties({
      listMasterStandard,listMasterStandardSpecial,
      listThickStandard: lstStandard.map(i => ({value: i, label: i.toString()})),
      listThickStandardSpecial: lstStandardSpecial.map(i => ({value: i, label: i.toString()}))
    }))
    } else {
      yield put( setAppProperties({
        listMasterStandard: [], listMasterStandardSpecial:[],listThickStandardSpecial:[],listThickStandard:[]
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterStandard Error", error);
  }
}

// danh sách máy xẻ
export function* handleLoadMasterSplitMachine(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_SplitMachine)

    HLog("handleLoadMasterSplitMachine response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      listSplitMachine: data
    }))
    } else {
      yield put( setAppProperties({
        listSplitMachine: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterSplitMachine Error", error);
  }
}


