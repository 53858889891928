import configs from "./configs";

const apis = {

    Url_Api_Login: configs.Domain + 'login',
    Url_Api_QuenMatKhau: configs.Domain + 'forgot-password',
    Url_Api_KiemTraTokenDoiMK: configs.Domain + 'recovery-auth-password',
    Url_Api_DoiMK: configs.Domain + 'update-recovery-password',
    Url_Api_ListUser: configs.Domain + 'find-by-user-id',

    Url_Get_HomepageDataChart: configs.Domain + 'dashboard/get_reportchart',

    Url_Get_ListMessageTokenByRole: configs.Domain_S + 'firebase/list-notice-token',
    Url_Get_SendNotification: configs.Domain_S + 'firebase/send-notification',

    Url_Api_Post_ThemChiThiNhapKho : configs.Domain + 'roll/import-roll-producer-draft',
    Url_Api_Post_LayDanhSachNhapKho : configs.Domain + 'roll/find-roll-producer',
    Url_Api_Post_EditThongTinTonCuon : configs.Domain + 'roll/update-save-roll-producer',
    Url_Api_Post_LayDanhSachMauKeoThuTheoId : configs.Domain + 'roll/list-pulling-by-roll-id',
    Url_Api_Post_TaoMauKeoThu : configs.Domain + 'roll/input-full-pulling',
    Url_Api_Post_LuuMauKeoThu : configs.Domain + 'roll/update-pulling',
    Url_Api_Post_XoaMauKeoThu : configs.Domain + 'roll/delete-pulling',
    Url_Api_Post_HuyChiThiNhapKho : configs.Domain + 'roll/delete-roll-nsmv',
    Url_Api_Post_YeuCauBangXe : configs.Domain + 'tape/manufacture-tape',
    Url_Api_Post_DanhSachXeBang: configs.Domain + 'tape/get-manufacture-tape',
    Url_Api_Post_DownloadXeBang: configs.Domain + 'tape/download-manufacture-tape',
    Url_Api_Post_EditXeBang: configs.Domain + 'tape/edit-manufacture-tape',
    Url_Api_Post_InXeBang: configs.Domain + 'tape/print-manufacture-tape',
    
    //Url_Api_Post_LayDanhSachTonKho : configs.Domain + 'roll/find-roll-inventory',
    Url_Api_Post_LayDanhSachTonKho : configs.Domain + 'roll/find-roll-inventory-status',
    Url_Api_Post_ExportDanhSachTonKho : configs.Domain + 'roll/download-excel-inventory',
    Url_Api_Post_InPdfNhan: configs.Domain + "roll/download-qr-pdf",
    Url_Api_Post_DetailRoll: configs.Domain + "roll/get-detail-roll",

    Url_Api_Post_PheDuyetChiThiXuatKho : configs.Domain + 'roll/access-request-import-roll-draft',
    Url_Api_Post_TaoChiThiXuatKho : configs.Domain + 'roll/create-deliver-roll',
    Url_Api_Post_LayDSChiThiXuatKho : configs.Domain + 'roll/list-request-roll',
    Url_Api_Post_LayDSXuatKho : configs.Domain + 'roll/list-deliver-roll',
    Url_Api_Post_XacNhanChiThiXuatKho : configs.Domain + 'roll/access-deliver-roll',
    Url_Api_Post_HuyChiThiXuatKho : configs.Domain + 'roll/cancel-deliver-roll',
    Url_Api_Get_Coil_Export_Request_Detail: configs.Domain + "roll/detail-request-roll",
    Url_Api_Post_ExportDanhSachXuatKho : configs.Domain + 'roll/download-excel-roll-pull',
    Url_Api_Post_Update_Deliver_Date : configs.Domain + 'roll/update-roll-deliver-date',
    Url_Api_Post_ExportDanhSachChiThiXuatKho : configs.Domain + 'roll/download-excel-instruct-deliver',
    Url_Api_Post_XuatKho1Phan : configs.Domain + 'roll/deliver-roll-percent',
    Url_Api_Post_DanhSachXuat1Phan : configs.Domain + 'roll/get-deliver-weight-percent',

    Url_Api_Get_KiemKe_DanhSachMaChiThiKiemKe: configs.Domain + "roll/inventory/list_inventory_head_date",
    Url_Api_Get_KiemKe_XemKetQua: configs.Domain + "roll/inventory/view_result",
    Url_Api_Post_KiemKe_TaoChiThi: configs.Domain + "roll/inventory/request_inventory",
    Url_Api_Post_KiemKe_LayDanhSachTonKho: configs.Domain + "roll/inventory/list_coil_inventory",
    Url_Api_Post_KiemKe_TaoMoiChiThi: configs.Domain + "roll/inventory/request_inventory",
    Url_Api_Post_KiemKe_DownloadResult: configs.Domain + "roll/inventory/download_result",
    Url_Api_Post_KiemKe_ThongKe: configs.Domain + "dashboard/get_reportMonths",

    Url_Api_Post_BaoCao_ExportTheoNgay: configs.Domain + "roll/download-excel-roll-import",
    Url_Api_Post_BaoCao_ExportBCThongKe: configs.Domain + "dashboard/download_get_reportMonths",
    Url_Api_Post_BaoCao_ExportBCThucTe: configs.Domain + "dashboard/get-report-two-time",

    Url_Api_Get_ListEmailUser: configs.Domain + "list-email-user",

    Url_Api_Get_MasterData_Shift : configs.Domain + "roll/get-master-shift",
    Url_Api_Get_MasterData_Team : configs.Domain + "roll/get-master-team",
    Url_Api_Get_MasterData_SteelType : configs.Domain + "roll/get-master-steel-type",
    Url_Api_Get_MasterData_CustomerType : configs.Domain + "roll/get-type-customer-master",
    Url_Api_Add_MasterData_SteelType : configs.Domain + "roll/insert-steel-type",
    Url_Api_Get_MasterTape_RollManufacture: configs.Domain + "tape/master-roll-manufacture",
    Url_Api_Get_MasterData_SplitMachine: configs.Domain + "roll/get-master-split-machine",

    Url_Api_Get_MasterTape_Standard: configs.Domain + "tape/master-standard-tape" ,
    Url_Api_Update_Split_Tape_Standard: configs.Domain + "roll/update_split_tape_standard",
    Url_Api_Create_Split_Tape_Standard: configs.Domain + "roll/create_split_tape_standard",
    Url_Api_Delete_Split_Tape_Standard: configs.Domain + "roll/delete_split_tape_standard",
};

export default apis;
