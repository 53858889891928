import { apis, keys } from "./constants";
// import cryptoRandomString from "crypto-random-string";
import axios from "axios";
// import sha256 from "sha256";
import { locale } from "moment/moment";
import moment from "moment";
import { languageKeys, languages, Translate } from "./i18n";
import { en, jp, vi } from "./i18n/languages";
// import * as XLSX from "xlsx";
// import * as FileSaver from "file-saver";

// convert percent to decimal
export function toDecimal(percent) {
  return parseFloat(percent) / 100;
}

export function checkEmptyData (input, defaultValue = null) {
  if(!!input){
    return input;
  }
  return defaultValue
}

export function formatWeightText (input) {
  if (!['string', 'number'].includes(typeof input)) return '';
  input = input.toString().trim();
  if (!input) return '';

  let data_split = input.split('.');
  let data_int = data_split[0];
  data_split.shift();
  //console.log('Data split', data_split);
  if (data_split.length == 0) data_split.push('0');

  if (data_int.length < 4) return [data_int, ...data_split].join('.');

  let sub_data = data_int.substring(0, data_int.length % 3);
  let data_match = data_int.substring(data_int.length % 3, data_int.length);
  data_match = data_match.match(/.{1,3}/g);
  let data_final = !!sub_data ? [sub_data, ...data_match] : data_match;
  data_final = data_final.join(',');
  return [data_final, ...data_split].join('.');
}

export function formatTensileStrength (val1, val2, val3) {
  const f1 = val1 ? formatWeightText(val1) + "N/mm²" : "-";
  const f2 = val2 ? formatWeightText(val2) + "kN" : '-';
  const f3 = val3 ? formatWeightText(val3) + "N/mm²" : "-";
  return f1 + " × " + f2 + " × " + f3;
}

export const formatThickWidth = (thick, width) => {
  const f1 = thick ? formatWeightText(thick) + 'mm' : '-';
  const f2 = width ? formatWeightText(width) + 'mm' : '-';
  return f1 + ' × ' + f2;
};

export function throttle(fn, ms) {
  let timeout
  function exec() {
      fn.apply()
  }
  function clear() {
    if( timeout === undefined ){

    }else{
      clearTimeout(timeout)
    }
  }
  if(fn !== undefined && ms !== undefined) {
      timeout = setTimeout(exec, ms)
  } else {
      console.error('callback function and the timeout must be supplied')
  }
  // API to clear the timeout
  throttle.clearTimeout = function() {
      clear();
  }
}

//lấy locale ngon ngữ hiện tại
export const getCurrentLocale = () => {
  return locale()
}

export const getImageUrl = (name) => {
  if(typeof name === 'string' && name.includes('static')){
    return name
  }else{
    return apis.Post_Image_Get + name
  }
}

// lấy text ngôn ngữ hiện tại dựa theo locale
export const getLanguage = () => {
  switch (Translate(languageKeys.Language_label)) {
    case en.Language_label:
      return languages.tieng_anh;
    case vi.Language_label:
      return languages.tieng_viet;
    case jp.Language_label:
      return languages.tieng_nhat_api;
    default:
      return ""
  }
}

// lấy text ngôn ngữ hiện tại dựa theo locale
export const convertLanguageKey = (current) => {
  switch (current) {
    case languages.tieng_anh:
      return languages.tieng_anh;
    case languages.tieng_viet:
      return languages.tieng_viet;
    case languages.tieng_nhat_api:
    case languages.tieng_nhat:
      return languages.tieng_nhat_api;
    default:
      return ""
  }
}

export const localGet = (key, fallback = "") => {
  const val = localStorage.getItem(key);
  HLog("Auth Saga getLocal key:", key, "with data", val);
  if (!val || val === "undefined" || val === "") return fallback;
  return JSON.parse(val);
};

export const localSet = (key, val) => {
  HLog("Auth Saga setLocal key:", key, "with data", val);
  return localStorage.setItem(key, JSON.stringify(val));
};

export const localRemove = (key) => {
  return localStorage.removeItem(key);
};

// random id (default: 5 chữ cái)
export const rid = (length = 5) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function HLog() {
  if (keys.enable_app_log) {
    console.log.apply(this, arguments);
  }
}

// lấy url ảnh để Preview
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// Hàm POST
export async function common_post(url, body, useToken = true) {
  try {
    let token = localGet(keys.access_token);
    let config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
        
        Authentication: ""
      },
    };
    if (!useToken) config = {};
    HLog(
      "Common_post request url: " +
        url +
        " body: " +
        JSON.stringify(body) +
        " config: " +
        JSON.stringify(config)
    );
    let dataResponse = await axios.post(url, body, config);
    let result = dataResponse.data;
    HLog(
      "Common_post response url: " + url + " result: " + JSON.stringify(result)
    );
    return result;
  } catch (error) {
    HLog("post error", error);
    return null;
  }
}

//đổi millisecond sang date DD/mm/yyy
export function convertMillisecondToDate(
  duration,
  separator = "/",
  dateOnly = false
) {
  if (duration == null || duration === "") {
    return "";
  }
  let outPut = "";
  duration = Number(duration);
  var isoFormat = new Date(duration);
  let year = isoFormat.getFullYear();
  let month = isoFormat.getUTCMonth() + 1;
  let date = isoFormat.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }
  if (dateOnly) {
    outPut = date + separator + month;
  } else {
    outPut = date + separator + month + separator + year;
  }
  return outPut;
}

// đổi milisecond sang giờ
export function convertTimeUTCToTime(time) {
  let date = new Date();
  date.setUTCHours(Number(time.split(":")[0]));
  date.setUTCMinutes(Number(time.split(":")[1]));
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let result = hours + ":" + minutes;
  return result;
}

//đổi millisecond dạng UTC sang thời gian
export function convertMilliUTCToTime(duration) {
  if (duration == null || duration === "") return "";
  var minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt((duration / (1000 * 60 * 60)) % 24);
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let time = hours + ":" + minutes;
  let result = convertTimeUTCToTime(time);
  return result;
}

// chuyển từ DD//MM/YYY sang MMMMYYDD
export const convertDateToValue = (dateString) => {
  if (dateString && dateString !== "") {
    return dateString.split("/").reverse().join("");
  }
  return "";
};

// chuyển từ MMMMYYDD sang DD//MM/YYY
export const convertValueToDate = (string) => {
  if (string && string !== "") {
    let year = string.slice(0, 4);
    let moth = string.slice(4, 6);
    let date = string.slice(6, 8);
    return date + "/" + moth + "/" + year;
  }
};

// chuyển từ MMMMYYDD sang DD//MM/YYY
export const convertValueToMilisecond = (string) => {
  let milisecond;
  if (string && string !== "") {
    let year = string.slice(0, 4);
    let moth = string.slice(4, 6);
    let date = string.slice(6, 8);
    milisecond = new Date(year, moth, date).getTime();
  }
  return milisecond;
};

//hàm format đơn vị tiền tệ thêm 3 số 0 vào cuối (isFull = false thì thêm .000)
export function formatCurrency(num, currency = "", isFull = false) {
  if (!num || num === "") {
    let response = "0 " + currency;
    return response;
  }
  num = Number(num);
  if (num === 0) {
    return "0 " + currency;
  }
  if (num.length === 2) {
    if (num === "00") {
      num = num.replace("00", "0");
    }
  }
  if (num.length > 1) {
    let first = num.substring(0, 1);
    if (first === "0") {
      num = num.substring(1, num.length);
    }
  }
  let result = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  if (!isFull) {
    result = result + ".000 ";
  }
  return result + currency;
}

//format định dạng giá khi thao tác ô input giá
export const formatNumberToPrice = (x) => {
  if (x === "" || x === 0) {
    return 0;
  }
  x = x.toString();
  x = x.replace(/ /g, "");
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1.$2");
  return x;
};

export const formatPriceToNumber = (x) => {
  x = x.toString();
  if (x === 0 || x === "") {
    return 0;
  }
  while (x.charAt(0) === 0 && x.length > 1) {
    x = x.substr(1);
  }
  x = x.replace(/ /g, "");
  return x.replace(/[.]+/g, "").trim();
};

//hàm validate giá nhập vào và trả lại theo dạng 123.455.232
export const validatePriceInput = (input) => {
  if (!input) {
    return 0;
  }
  HLog("onchangePrice", input);
  input = input.replace(/[^0-9.]/g, "");
  input = formatPriceToNumber(input);
  input = formatNumberToPrice(input);
  HLog("new", input);
  return input;
};

// export const encrypt256 = async (key) => {
//   HLog("my key: " + key);
//   try {
//     const encrypted = await sha256(key);
//     return encrypted;
//   } catch (error) {
//     HLog("cant encrypt key...", error);
//     return null;
//   }
// };

//kiểm tra đối tượng object có rỗng hay không
export function isEmptyObject(obj) {
  return JSON.stringify(obj) === "{}";
}

//in hoa chữ cái đầu mỗi từ
export function capital_letter(str) {
  let result = "";
  if (str) {
    str = str.replace(/\s{2,}/g, " ");
    str = str.split(" ");
    // HLog(str);

    for (var i = 0, x = str.length; i < x; i++) {
      if (!!str[i][0]) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }
    }
    result = str.join(" ");
  }

  return result;
}

//hàm chuyển đổi firstName, lastName thành fullTime theo ngôn ngữ
export function getFullName(objectInfo) {
  HLog("Utils getFullName objectInfo: " + JSON.stringify(objectInfo));
  if (objectInfo != null && !isEmptyObject(objectInfo)) {
    // let lang = getLocale();
    let firstName = objectInfo.HO;
    let lastName = objectInfo.TEN;
    if (!!firstName && !!lastName) {
      let userName = capital_letter(firstName) + " " + capital_letter(lastName);
      return userName.trim();
    } else {
      firstName = objectInfo.first_name;
      lastName = objectInfo.last_name;
      if ((!firstName && firstName !== "") || (!lastName && lastName !== "")) {
        let name = objectInfo?.full_name || objectInfo.name;
        if (name) {
          return capital_letter(name);
        }
        return "";
      }
      let userName = capital_letter(firstName) + " " + capital_letter(lastName);
      return userName.trim();
    }
  } else {
    return "";
  }
}

//hàm trả về tuổi của người dùng
export function convertMillisecondToAge(millisecond) {
  // Dành cho trường hợp giá trị đầu vào là dd/mm/yyyy @.@
  if (typeof millisecond === "string" && millisecond.includes("/")) {
    const arrNoSlash = millisecond.split("/");

    if ([...arrNoSlash].length === 3) {
      millisecond = moment(millisecond, "DD/MM/YYYY").valueOf();
    }
  }

  millisecond = Number(millisecond); // đổi input thành number

  // nếu milli = NaN hoặc milli = 0 thì return "--"
  if (isNaN(millisecond) || millisecond === 0) {
    return "--";
  }

  // đổi input (milli) về gap giữa hiện tại và input
  let duration = Math.floor(
    moment.duration(moment().diff(moment(millisecond))).asYears()
  );

  // nếu gap > 0 thì trả về số tuổi (gap được làm tròn xuống)
  if (duration > 0) {
    return `${duration} tuổi`;
  }
  // ngược lại thì trả về số tháng tuổi (gap cũng được làm tròn xuống)
  else {
    duration = Math.floor(
      moment.duration(moment().diff(moment(millisecond))).asMonths()
    );
    return `${duration} tháng`;
  }
}

// lay gioi tinh
export function getSex(value) {
  let str_return = "";
  if (!value || value === 0 || value === "0") {
    //str_return = t(languageKeys.PSO__MODAL_DETAIL__SEX_FEMALE);
    str_return = "Nữ";
  } else if (value === 1 || value === "1") {
    // str_return = t(languageKeys.PSO__MODAL_DETAIL__SEX_MALE);
    str_return = "Nam";
  } else if (value === 2) {
    // str_return = t(languageKeys.PSO__MODAL_DETAIL__SEX_OTHER);
    str_return = "Khác";
  }
  return str_return;
}

// hàm lấy danh sách tỉnh thành
export const getAllProvinces = async () => {
  const response = await axios.get(
    "https://api.deepcare.vn/address/getAllProvince"
  );

  const { status, data } = response.data;

  if (status === "OK") {
    return data;
  }

  return [];
};

// hàm lấy danh sách quận huyện
export const getAllDistricts = async (code) => {
  const response = await axios.post(
    "https://api.deepcare.vn/address/searchByCode",
    {
      address_type: 1,
      code: code,
    }
  );
  const { status, data } = response.data;

  if (status === "OK") {
    return data;
  }

  return [];
};

// hàm lấy danh sách xã phường
export const getAllWards = async (code) => {
  const response = await axios.post(
    "https://api.deepcare.vn/address/searchByCode",
    {
      address_type: 2,
      code: code,
    }
  );

  const { status, data } = response.data;

  if (status === "OK") {
    return data;
  }

  return [];
};

//hàm lấy thời gian hiển thị cho cuộc hẹn tùy theo type cuộc hẹn
export function getTimeScheduleByType(record) {
  if (!record || !record.schedule_type) {
    return "";
  }

  if (record.schedule_type === keys.section) {
    return (
      convertMilliSlotToTime(record.gio_bat_dau) +
      " - " +
      convertMilliSlotToTime(record.gio_ket_thuc)
    );
  } else {
    return convertMilliSlotToTime(record.gio_kham);
  }
}

//hàm đổi thời gian khám millisecond UTC sang thời gian hiện tại
export function convertMilliSlotToTime(milli) {
  if (milli === null || milli === undefined || milli === "") {
    return;
  }
  if (typeof milli == "string") {
    return milli;
  }
  let duration = Number(milli);
  var //   milliseconds = Number((duration % 1000) / 100),
    //     seconds = Number((duration / 1000) % 60),
    minutes = Number((duration / (1000 * 60)) % 60),
    hours = Number((duration / (1000 * 60 * 60)) % 24);

  let newhours = hours < 10 ? "0" + hours : hours;
  let newminutes = minutes < 10 ? "0" + minutes : minutes;
  //   let newseconds = seconds < 10 ? "0" + seconds : seconds;
  let times = newhours + ":" + newminutes;
  return convertTimeUTCToTime(times);
}

//lấy ký tự đầu tiên của học hàm, học vị bác sĩ
export function getEducation(item) {
  if (item == null || isEmptyObject(item)) {
    return "";
  }
  let education = "";
  let academic_rank_name = item.academic_rank_name;
  let degree_name = item.degree_name;
  //học hàm giáo sư, phó giáo sư, tiến sĩ
  if (academic_rank_name != null && academic_rank_name !== "") {
    education = getFirstCharactor(academic_rank_name);
  }
  //học vị, thạc sĩ, bác sĩ...
  if (degree_name != null && degree_name !== "") {
    let result = getFirstCharactor(degree_name);
    if (degree_name.includes("Thạc")) {
      result = "ThS";
    } else if (degree_name.includes("Cử nhân")) {
      result = "BS";
    }
    if (education !== "") {
      education = education + "." + result;
    } else education = result;
  }
  if (education === "") {
    return "BS";
  }
  return education;
}

//lấy ký tự đầu tiên của học hàm, học vị
function getFirstCharactor(txtCharactor) {
  let result = "";
  let arrCharactor = txtCharactor.toLocaleUpperCase().split(" ");
  for (let i = 0; i < arrCharactor.length; i++) {
    let text = arrCharactor[i];
    //nếu là bác sĩ chuyên khoa 2
    if (text === "II") {
      return "BS.CKII";
    } else if (text === "I") {
      //nếu là bác sĩ chuyên khoa 1
      return "BS.CKI";
    }
    result = result + (text ? text.charAt(0).toUpperCase() : "");
  }
  return result;
}

//lấy ra millisecond thời gian theo giờ GMT
export function getTimeMillisecondUTC() {
  let date = new Date();
  let formatdateUTC = date.toUTCString();
  let output = new Date(formatdateUTC).getTime();
  return output;
}

// hàm đọc file excel
// export const readExcel = (file, onOk = () => {}) => {
//   const promise = new Promise((resolve, reject) => {
//     const fileReader = new FileReader();
//     fileReader.readAsArrayBuffer(file);

//     fileReader.onload = (e) => {
//       const bufferArray = e.target.result;

//       const wb = XLSX.read(bufferArray, { type: "buffer" });

//       const wsname = wb.SheetNames[0];

//       const ws = wb.Sheets[wsname];

//       const data = XLSX.utils.sheet_to_json(ws);

//       resolve(data);
//     };

//     fileReader.onerror = (error) => {
//       reject(error);
//     };
//   });

//   promise.then((d) => {
//     HLog(d);
//     onOk(d);
//   });
// };

// export const exportToCSV = (csvData = [], fileName = "data") => {
//   const fileType =
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//   const fileExtension = ".xlsx";

//   const ws = XLSX.utils.json_to_sheet(csvData);
//   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
//   const data = new Blob([excelBuffer], { type: fileType });
//   FileSaver.saveAs(data, fileName + fileExtension);
// };


export const getPullingSampleResult = (result) => {
  if(result === null){
    return "Chưa có kết quả"
  }else{
    return Number(result) === 1 ? "Đạt" : "Không đạt"
  }

}