import { Breadcrumb, Button, DatePicker, Row, Select, Column, Col, Input, Table, Flex, Pagination, notification, Checkbox, Popover } from "antd";
import { Link } from "react-router-dom";
import { apis, keys, paths } from "../../../constants";
import style from "./danhSachTonKho.module.less";
import { ArrowDownOutlined, CloseCircleFilled, DeleteOutlined, DownloadOutlined, FilterOutlined, FormOutlined, PlusOutlined, SearchOutlined, SettingOutlined, TagFilled } from "@ant-design/icons";
import { IconEraser } from "../../../assets/svg";
import { useEffect, useRef } from "react";
import AxiosService from "../../../common/AxiosService";
import { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { checkEmptyData, formatWeightText, getPullingSampleResult, localGet } from "../../../helpers";
import cn from 'classnames'
import dayjs from 'dayjs'
import configs from "../../../constants/configs";
import { useDispatch, useSelector } from "react-redux";
import ChiTietTonCuon from "./ChiTietTonCuon/ChiTietTonCuon";
import { LoadingModal } from "../../../components/common";
import { appLoading } from "../../../ducks/slices/appSlice";
import { CONSIGNMENT, ROLL_COIL_STATUS } from "../../../constants/utils";
import TaoChiThiXuatKhoModal from "../XuatKho/TaoChiThiXuatKhoModal";
import { sumBy } from "lodash";

const DanhSachTonKho = () => {
  const [data, set_data] = useState([]);
  const [total, set_total] = useState(0);
  const [totalWeight, set_totalWeight] = useState(0);

  const [providerName, set_providerName] = useState('')
  const [coilId, set_coilId] = useState('')
  const [steelGrade, set_steelGrade] = useState('')
  const [importDate, set_importDate] = useState()
  const [steelType, set_steelType] = useState('')
  const [netWeight, set_netWeight] = useState('')
  const [sizeWidth, set_sizeWidth] = useState('')
  const [sizeDepth, set_sizeDepth] = useState('')
  const [sizeWidthTo, set_sizeWidthTo] = useState('')
  const [sizeDepthTo, set_sizeDepthTo] = useState('')
  const [rollStatus, set_rollStatus] = useState(null);
  const [consignment, set_consignment] = useState(null);
  const [currentDataRequest, set_currentDataRequest] = useState({})
  const [currentPage, set_currentPage] = useState(1);
  const [selectedRecord, set_selectedRecord] = useState([])

  const [loading, set_loading] = useState(false)
  const [tableConfig, set_tableConfig] = useState(configSample)
  const [openConfig, set_openConfig] = useState(false)

  const history = useHistory();
  const detailRef = useRef();
  const [pageSize, set_pageSize] = useState(10);
  const app = useSelector(state => state.app)
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch();

  useEffect(() => {
    initData();
    onPressClear();
  }, [auth.wareHouseName])

  const initData = () => {
    let dataRequest = {
      start: 0,
      length: pageSize,
      draft: null,
      status: null,
      providerName: null,
      steelType: null,
      rollNsmvId: null,
      steelGrade: null,
      importDate: null,
      netWeight: null,
      sizeWidth: null,
      sizeDepth: null,
      sizeWidthTo: null,
      sizeDepthTo: null,
      saveInStock: 1,
      sortRequest: {
        importDate : "desc",
        nsmvCoilId : "asc",
        steelGrade : null,
        steelType : null,
        sizeDepth : null,
        sizeWidth : null,
        netWeight : null,
        temWeight: null,
        providerName : null,
        producerCode : null,
        note : null
      },
      consignment:null,
    }

    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const onPressFilter = () => {
    let sizeRangeValidate = getSizeRangeValidate();
    set_currentPage(1)
    let dataRequest = {
      start: 0,
      length: pageSize,
      draft: null,
      status: rollStatus,
      consignment:consignment,
      providerName: checkEmptyData(providerName),
      steelType: checkEmptyData(steelType),
      rollNsmvId: checkEmptyData(coilId),
      steelGrade: checkEmptyData(steelGrade),
      importDate: !!checkEmptyData(importDate) ? dayjs(importDate).format('DD/MM/YYYY') : null,
      netWeight: checkEmptyData(netWeight),
      sizeWidth: checkEmptyData(sizeRangeValidate.sizeWidth),
      sizeDepth: checkEmptyData(sizeRangeValidate.sizeDepth),
      sizeWidthTo: checkEmptyData(sizeRangeValidate.sizeWidthTo),
      sizeDepthTo: checkEmptyData(sizeRangeValidate.sizeDepthTo),
      saveInStock: 1,
      sortRequest: {
        importDate : "desc",
        nsmvCoilId : "asc",
        steelGrade : null,
        steelType : null,
        sizeDepth : null,
        sizeWidth : null,
        netWeight : null,
        temWeight: null,
        providerName : null,
        producerCode : null,
        note : null
      }
    }

    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const getSizeRangeValidate = () => {
    let requestSize = {
      sizeWidth: '',
      sizeDepth: '',
      sizeWidthTo: '',
      sizeDepthTo: '',
    }

    if (sizeDepth == '' && sizeDepthTo != '') {
      requestSize.sizeDepth = "";
      requestSize.sizeDepthTo = sizeDepthTo;
      // set_sizeDepth(sizeDepthTo)
      // set_sizeDepthTo('')
    } else if (sizeDepthTo == '' && sizeDepth != '') {
      requestSize.sizeDepth = sizeDepth;
    } else if (sizeDepthTo != '' && sizeDepth != '') {
      if (Number(sizeDepthTo) < Number(sizeDepth)) {
        set_sizeDepth(sizeDepthTo)
        set_sizeDepthTo(sizeDepth)
        requestSize.sizeDepth = sizeDepthTo;
        requestSize.sizeDepthTo = sizeDepth;
      } else {
        requestSize.sizeDepth = sizeDepth;
        requestSize.sizeDepthTo = sizeDepthTo;
      }
    } else {
      //do nothing
    }

    if (sizeWidth == '' && sizeWidthTo != '') {
      requestSize.sizeWidth = "";
      requestSize.sizeWidthTo = sizeWidthTo;
      // set_sizeWidth(sizeWidthTo)
      // set_sizeWidthTo('')
    } else if (sizeWidthTo == '' && sizeWidth != '') {
      requestSize.sizeWidth = sizeWidth;
    } else if (sizeWidthTo != '' && sizeWidth != '') {
      if (Number(sizeWidthTo) < Number(sizeWidth)) {
        set_sizeWidth(sizeWidthTo)
        set_sizeWidthTo(sizeWidth)
        requestSize.sizeWidth = sizeWidthTo;
        requestSize.sizeWidthTo = sizeWidth;
      } else {
        requestSize.sizeWidth = sizeWidth;
        requestSize.sizeWidthTo = sizeWidthTo;
      }
    } else {
      //do nothing
    }

    return requestSize;
  }

  const requestGetData = (dataRequest) => {
    console.log("Request", dataRequest)
    set_loading(true)
    AxiosService.post(apis.Url_Api_Post_LayDanhSachTonKho, dataRequest)
      .then(response => {
        let { data } = response;
        if (!!data && data.message === "OK") {
          console.log("Data inventory", data.data)
          if (dataRequest.start === 0) {
            set_total(data.totalRecords)
            set_totalWeight(data.totalWeight)
          }
          set_data(data.data)
        } else if (data.message === keys.api_message_EMPTY) {
          set_total(0)
          set_data([])
          set_totalWeight(0)
        }
        set_loading(false)

      })
      .catch(err => {
        set_loading(false)
        notification.error({ message: "Lỗi hệ thống" })
      })
  }

  const onChangePageSize = (val) => {
    set_pageSize(val);
    set_currentPage(1);
    let dataRequest = {
      ...currentDataRequest,
      start: 0,
      length: val,
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const onChangePage = (val) => {
    set_currentPage(val)
    let dataRequest = {
      ...currentDataRequest,
      start: pageSize * (val - 1),
      length: pageSize * val,
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const onPressAdd = () => {
    history.push(paths.main_them_moi_nhap_kho);
  }

  const onPressExportLabel = async () => {
    dispatch(appLoading(true));
    var listUserName = []
    try {
      // cần sửa lại giá trị userId default này
      const {data} = await AxiosService.post(apis.Url_Api_ListUser, {lstId: selectedRecord.map(item => item.importUserId || 6)});
      listUserName = data.data.map(u => u.fullname);
    }
    catch (e) {
      notification.error({message:"Không tìm thấy user"})
    }
    const transformRecord = selectedRecord.map((record,index) => ({...record, creatorFullname: listUserName[index] }));

    fetch(apis.Url_Api_Post_InPdfNhan, {
      method: 'POST',
      body: JSON.stringify({
        "listQrRequest": transformRecord,
        "wareHouseName": auth.wareHouseName
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: await localGet(keys.access_token),
      },
    })
      .then(response => response.blob())
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res], {type: 'application/pdf'}));

       // Create an iframe element
       const iframe = document.createElement('iframe');
       iframe.style.display = 'none';  // Hide the iframe
       iframe.src = url;

      // Wait for the iframe to load the content
      iframe.onload = function () {
      // Invoke the print function of iframe's content
        iframe.contentWindow.print();
      };
       // Append iframe to body
       document.body.appendChild(iframe);
        dispatch(appLoading(false))
      }).catch(error => {
        dispatch(appLoading(false))
        notification.error({ message: "Lỗi hệ thống" })
      });
  }

  const doExportImportDataByDate = async () => {
    dispatch(appLoading(true))
    try {
      const dataRequest = { ...currentDataRequest, wareHouseName: auth.wareHouseName }
      delete dataRequest.length;
      delete dataRequest.start;

      fetch(apis.Url_Api_Post_ExportDanhSachTonKho, {
        method: 'POST',
        body: JSON.stringify(dataRequest),
        headers: {
          "Content-Type": "application/json",
          Authorization: await localGet(keys.access_token),
        },
      })
        .then(response => response.blob())
        .then(res => {
          console.log("Respponse", res)
          const url = window.URL.createObjectURL(new Blob([res]));
          console.log("URL", url)

          const link = document.createElement('a');
          link.href = url;
          link.download = "DS_TonKho_" + moment().format('YYYY-MM-DD') + ".xlsx";

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
          dispatch(appLoading(false))
        }).catch(error => {
          dispatch(appLoading(false))
          notification.error({ message: "Không có dữ liệu" })
        });
    } catch (error) {
      console.log(error)
      notification.error({ message: "Không có dữ liệu" })
    }

  }

  const onPressClear = () => {
    set_importDate('')
    set_providerName('')
    set_coilId('')
    set_steelGrade('')
    set_importDate('')
    set_steelType('')
    set_netWeight('')
    set_sizeWidth('')
    set_sizeDepth('')
    set_sizeWidthTo('')
    set_sizeDepthTo('')
    set_rollStatus(null);
    set_consignment(null)
  }

  const handleOpenChange = (boolean) => {
    set_openConfig(boolean);
  };

  const onSelectRow = (record) => {
    if (selectedRecord.some(item => item.id === record.id)) {
      set_selectedRecord(currentData => currentData.filter(item => item.id !== record.id))
    } else {
      set_selectedRecord(currentData => [...currentData, record])
    }
  }


  const selectAllCurrentList = ({ target }) => {
    if (target.checked) {
      set_selectedRecord(currentData => [...currentData, ...data.filter((record) => !currentData.map(item => item.id).includes(record.id))])
    } else {
      set_selectedRecord(currentData => currentData.filter(record => !data.map(item => item.id).includes(record.id)))
    }
  }

  
  const handleChange = (pagination, filters, sorter, extra) => {
    let newSortRequest;

    if (!Array.isArray(sorter)) {
        const { field, order } = sorter;
        const normalizedOrder = order === "ascend" ? "asc" : "desc";
        const orderValue = order ? normalizedOrder : null; 
        newSortRequest = { [field]: orderValue };
    } else {
        newSortRequest = sorter.reduce((acc, item) => {
            if (item.order) {
                const normalizedOrder = item.order === "ascend" ? "asc" : "desc";
                acc[item.field] = normalizedOrder;
            }
            return acc;
        }, {});
    }

    const currentSortRequest = { ...currentDataRequest.sortRequest };
    for (const key in currentSortRequest) {
        if (!(key in newSortRequest)) {
            newSortRequest[key] = null; 
        }
    }

    if (newSortRequest.size) {
        newSortRequest.sizeDepth = newSortRequest.size;
        newSortRequest.sizeWidth = newSortRequest.size;
    }

    const finalSortRequest = { ...newSortRequest };

    const newCurrentDataRequest = { ...currentDataRequest, sortRequest: finalSortRequest };
    set_currentDataRequest(newCurrentDataRequest);
    requestGetData(newCurrentDataRequest);
};

// logic tạo chỉ thị xuất kho

// hiển thị ngày xuất kho
const [isApprove , setIsApprove] = useState(false);
// true sẽ xuất kho luôn không cần duyệt
const [isPressCreateTicket, setIsPressCreateTicket] = useState(false);
const [isOpenModal, setIsOpenModal] = useState(false);
const onPressCreateTicketApprove = ( ) => {
  if(selectedRecord.length === 0) {
    notification.error({message: "Chưa có cuộn nào được chọn"})
  }
  else if(selectedRecord.some(item => ['2','3'].includes(item.status))){
    notification.error({message: "Cuộn đã ở trong chỉ thị"})
  }
  else if(selectedRecord.some(item => ['1'].includes(item.status))){
    notification.error({message: "Cuộn đã được xuất kho"})
  }
  else {
    setIsPressCreateTicket(true)
    setIsApprove(true);
    setIsOpenModal(true)
  }
}

const onPressCreateTicket = ( ) => {
  if(selectedRecord.length === 0) {
    notification.error({message: "Chưa có cuộn nào được chọn"})
  }
  else if(selectedRecord.some(item => ['2','3'].includes(item.status))){
    notification.error({message: "Cuộn đã ở trong chỉ thị"})
  }
  else if(selectedRecord.some(item => ['1'].includes(item.status))){
    notification.error({message: "Cuộn đã được xuất kho"})
  }
  else {
    setIsPressCreateTicket(false)
    setIsOpenModal(true)
  }
}
//tạo chỉ thị ở modal 
const onClickSaveTicket = (misaNameAcronym = "SR", deliverDate, isFinish = false)=> {
  
  let dataRequest = {
    "listIdRollTable": selectedRecord.map(record => record.id),
    isRecentFinish: isFinish,
    misaNameAcronym,
    deliverDate
  }
  //console.log('Data request', dataRequest)
  dispatch(appLoading(true))
  AxiosService.post(apis.Url_Api_Post_TaoChiThiXuatKho, dataRequest)
    .then(response => {
      let { data } = response;
      if (!!data && data.message === "OK") {
        notification.success({message: "Tạo chỉ thị thành công"})
        history.push(paths.main_danh_sach_chi_thi_xuat_kho)
        set_selectedRecord([])
        requestSendNoti()
      }
      dispatch(appLoading(false))
    })
    .catch(err =>{ 
      console.log("Lỗi xuất",err);
      dispatch(appLoading(false));
      notification.error({message: "Lỗi hệ thống"});
    })
}

const requestSendNoti = async () => {
  try {
    const res = await AxiosService.post(apis.Url_Get_ListMessageTokenByRole, {
      "lstRole": [keys.userRoles.KhoTonCuon_PheDuyet]
    })
    
    if(res.data?.message === 'OK' && res.data?.status === 200) {
      var listToken = res.data.data[keys.userRoles.KhoTonCuon_PheDuyet];
      var dataRequest = {
        "subject" : "NSMP STORAGE",
        "content" : "Có yêu cầu xuất kho mới cần phê duyệt",
        "data" : {
           "message" : "xuất kho mới"
        },
        "registrationToken" : listToken?.map(item => item.token)
       }
      await AxiosService.post(apis.Url_Get_SendNotification, dataRequest);
    }
  }
  catch (e) {
    console.log("Cannot send notification", e)
  }
}

  const columns = [
    {
      title: <Checkbox onClick={selectAllCurrentList} checked={!data.some(record => !selectedRecord.map(item => item.id).includes(record.id)) && selectedRecord.length > 0} />,
      dataIndex: 'checked',
      key: 'checked',
      width: 35,
      render: (text, record) => <Checkbox onClick={(e) => {e.stopPropagation()}} checked={selectedRecord.some(item => item.id === record.id)} onChange={(e) => {e.stopPropagation(); onSelectRow(record)}}/>,
      fixed: 'left'
    },
    {
      title: 'Ngày nhập',
      titleConfig: 'Ngày nhập',
      dataIndex: 'importDate',
      key: 'importDate',
      width: 115,
      // render: (value) => moment(value).format('DD/MM/YYYY'),
      fixed: 'left',
      defaultSortOrder : currentDataRequest?.sortRequest?.importDate === "asc" ? "ascend" : "descend",
      sorter : true
      // sorter: {
      //   multiple: 1
      // }, 
    },
    {
      title: auth.wareHouseName == "X" ? "Số dải" :'Số cuộn',
      titleConfig: auth.wareHouseName == "X" ? "Số dải" :'Số cuộn',
      dataIndex: 'nsmvCoilId',
      key: 'nsmvCoilId',
      width: 150,
      render: (value) => <div className={style["important-text"]}>{value}</div>,
      fixed: 'left',
      sorter : true
      // sorter: {
      //   multiple: 2
      // },
    },
    auth.wareHouseName == "X" && {
      title: 'Số cuộn',
      titleConfig:'Số cuộn',
      dataIndex: 'nsmvRollCoilId',
      key: 'nsmvRollCoilId',
      width: 150,
      render: (value) => <div className={style["important-text"]}>{value}</div>,
      // fixed: 'left',
      sorter : true
      // sorter: {
      //   multiple: 2
      // },
    },
    {
      title: 'Mác thép',
      titleConfig: 'Mác thép',
      width: 115,
      dataIndex: 'steelGrade',
      key: 'steelGrade',
      sorter : true
      // sorter: {
      //   multiple: 3
      // },
    },
    {
      title: 'Chủng loại',
      titleConfig: 'Chủng loại',
      width: 115,
      dataIndex: 'steelType',
      key: 'steelType',
      sorter : true
      // sorter: {
      //   multiple: 4
      // },
    },
    {
      title: 'Dày x rộng(mm)',
      titleConfig: 'Dày x rộng',
      dataIndex: 'size',
      key: 'size',
      width: 150,
      render: (size, item) => formatWeightText(item.sizeDepth) + " × " + formatWeightText(item.sizeWidth),
      sorter : true
      // sorter: {
      //   multiple: 5
      // },
    },
    {
      title: 'Trọng lượng(kg)',
      titleConfig: 'Trọng lượng',
      dataIndex: 'temWeight',
      key: 'temWeight',
      width: 120,
      render: (value) => formatWeightText(value),
      sorter : true
      // sorter: {
      //   multiple: 6
      // },
    },
    // {
    //   title: '',
    //   dataIndex: 'netWeight',
    //   width: '20px',
    //   key: 'netWeight',
    //   render: (value) => <div>{getTag(value)}</div>,
    // },
  
    {
      title: 'Trọng lượng còn lại(kg)',
      titleConfig: 'Trọng lượng còn lại',
      dataIndex: 'netWeight',
      key: 'netWeight',
      width: 170,
      render: (value) => <div className={cn(Number(value) < 500 && style['warning-net-weight'], Number(value) < 100 && style['high'])}>{formatWeightText(value)}</div>,
      sorter : true
      // sorter: {
      //   multiple: 7
      // },
    },
    {
      title: 'Nhà cung cấp',
      titleConfig: 'Nhà cung cấp',
      width: 150,
      dataIndex: 'providerName',
      key: 'providerName',
      sorter : true
      // sorter: {
      //   multiple: 8
      // },
    },
    {
      title: 'Nhà sản xuất',
      titleConfig: 'Nhà sản xuất',
      width: 150,
      dataIndex: 'producerCode',
      key: 'producerCode',
      sorter : true
      // sorter: {
      //   multiple: 9
      // },
    },
    {
      title: 'Loại hàng tồn',
      titleConfig: 'Loại hàng tồn',
      width: 110,
      dataIndex: 'consignment',
      key: 'consignment',
      sorter : true,
      render: value => CONSIGNMENT.find(item => item.value)?.label
      // sorter: {
      //   multiple: 9
      // },
    },
    {
      title: 'Ghi chú',
      titleConfig: 'Ghi chú',
      width: 150,
      dataIndex: 'note',
      key: 'note',
      sorter : true
      // sorter: {
      //   multiple: 10
      // },
    },
   
    {
      title: 'Ngày thí nghiệm',
      titleConfig: 'Ngày thí nghiệm',
      width: 115,
      dataIndex: 'pullDate',
      render: (value, {pullingResponse}) => !!pullingResponse ? moment(pullingResponse.importDate).format('DD/MM/YYYY')  : '',
      key: 'pullDate'
      
    },{
      title: 'Loại/Kích thước kéo',
      titleConfig: 'Loại/Kích thước kéo',
      width: 150,
      dataIndex: 'typeSize',
      render: (value, {pullingResponse}) => !!pullingResponse ? pullingResponse.typeSize  : '',
      key: 'typeSize'
    },{
      title: 'Phương pháp kéo',
      titleConfig: 'Phương pháp kéo',
      width: 150,
      dataIndex: 'methodUsed',
      render: (value, {pullingResponse}) => !!pullingResponse ? pullingResponse.methodUsed  : '',
      key: 'methodUsed'
    },{
      title: 'Giới hạn chảy',
      titleConfig: 'Giới hạn chảy',
      width: 150,
      dataIndex: 'yieldStrength',
      render: (value, {pullingResponse}) => formatWeightText(pullingResponse?.yieldStrength),
      key: 'yieldStrength'
    },{
      title: 'Độ giãn dài',
      titleConfig: 'Độ giãn dài',
      width: 150,
      dataIndex: 'elongation',
      render: (value, {pullingResponse}) => formatWeightText(pullingResponse?.elongation),
      key: 'elongation'
    },{
      title: 'Giới hạn bền',
      titleConfig: 'Giới hạn bền',
      width: 150,
      dataIndex: 'tensileStrength',
      render: (value, {pullingResponse}) => {
        if(!pullingResponse) return ''
        return (formatWeightText(pullingResponse?.firstTensileStrength) || '-') + " × "  + (formatWeightText(pullingResponse?.secondTensileStrength) || '-')+ " × "  + (formatWeightText(pullingResponse?.thirdTensileStrength) || '-')
      },
      key: 'tensileStrength'
    },{
      title: 'Kết quả kéo',
      titleConfig: 'Kết quả kéo',
      width: 150,
      dataIndex: 'result',
      render: (value, {pullingResponse}) => !!pullingResponse ? getPullingSampleResult(pullingResponse.result)  : 'Chưa thí nghiệm',
      key: 'result'
    },{
      title: 'Ghi chú thí nghiệm',
      titleConfig: 'Ghi chú thí nghiệm',
      width: 150,
      dataIndex: 'pullNote',
      render: (value, {pullingResponse}) => !!pullingResponse ? pullingResponse.pullNote  : '',
      key: 'pullNote'
    },
    {
      title: 'Trạng thái',
      titleConfig: 'Trạng thái',
      width: 135,
      dataIndex: 'status',
      key: 'status',
      render: value => ROLL_COIL_STATUS.find(i => i.value == value)?.label || '',
      sorter : true,
    },
  
  ];

  const filterCol = columns.filter(item => {
    if (!!tableConfig[`${item.key}`] || !!item.fixed) {
      return true
    }
    return false
  });
  return (
    <div className={style["main-container"]}>
      <Breadcrumb items={[{
        title: 'Danh sách tồn kho',
      }]} />

      <div className={style['main']}>
        <Row gutter={[10, 20]} align={'middle'}>
          <Col span={2} className={style['filter-title']}>Nhà cung cấp</Col>
          <Col span={4}><Input className={style['filter-input']} value={providerName} onChange={({ target }) => set_providerName(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>{auth.wareHouseName == 'X' ? 'Số dải' : 'Số cuộn'}</Col>
          <Col span={4}><Input className={style['filter-input']} value={coilId} onChange={({ target }) => set_coilId(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>Mác thép</Col>
          <Col span={4}><Input className={style['filter-input']} value={steelGrade} onChange={({ target }) => set_steelGrade(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>Ngày nhập</Col>
          <Col span={4}><DatePicker format={"DD/MM/YYYY"} className={style['filter-input']} value={importDate} onChange={(date, dateString) => set_importDate(date)} /></Col>
          <Col span={2} className={style['filter-title']}>Chủng loại</Col>
          <Col span={4}><Select
            className={style['filter-select']}
            onChange={set_steelType}
            defaultValue={''}
            value={steelType}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={[{ value: '' , label: 'Tất cả' }].concat(app.steelTypeMaster)}
          /></Col>
          <Col span={2} className={style['filter-title']}>Trọng lượng</Col>
          <Col span={4}><Input className={style['filter-input']} value={netWeight} onChange={({ target }) => set_netWeight(target.value.replace(/[^0-9.]/g, ""))} /></Col>
          <Col span={2} className={style['filter-title']}>Dày × rộng</Col>
          <Col span={4}>
            <Flex align="center">
              <Input className={style['filter-input']} value={sizeDepth} onChange={({ target }) => set_sizeDepth(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidth} onChange={({ target }) => set_sizeWidth(target.value.replace(/[^0-9.]/g, ""))} />
            </Flex>

            <Flex align="center" justify='space-around'>
              <ArrowDownOutlined />
              <ArrowDownOutlined />
            </Flex>

            <Flex align="center">
              <Input className={style['filter-input']} value={sizeDepthTo} onChange={({ target }) => set_sizeDepthTo(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidthTo} onChange={({ target }) => set_sizeWidthTo(target.value.replace(/[^0-9.]/g, ""))} />
            </Flex>
          </Col>
          <Col span={2} className={style['filter-title']}>Trạng thái</Col>
          <Col span={4}><Select
            className={style['filter-select']}
            onChange={set_rollStatus}
            defaultValue={null}
            value={rollStatus}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={ROLL_COIL_STATUS}
          /></Col>
          <Col span={2} className={style['filter-title']}>Loại hàng</Col>
          <Col span={4}><Select
            className={style['filter-select']}
            onChange={set_consignment}
            value={consignment}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={CONSIGNMENT.concat({value:null, label:"Tất cả"})}
          /></Col>
        </Row>

        <Row gutter={60} align={'middle'} justify={'center'} style={{ marginTop: '20px' }}>
          <Col ><Button onClick={onPressFilter} icon={<SearchOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tìm kiếm</Button></Col>
          <Col ><Button onClick={onPressClear} icon={<IconEraser style={{ height: '16px', width: '16px' }} />} className={style['filter-button']}>Xóa</Button></Col>
          <Col ><Button onClick={doExportImportDataByDate} icon={<DownloadOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tải xuống</Button></Col>
        </Row>

        <Row style={{ marginTop: '40px' }} align={'middle'} justify={'space-between'}>
          <Col flex={'auto'}>
            <Row align={'middle'}>
              <Col >Tổng số: {total}</Col>
              <Col style={{ marginLeft: '20px', paddingLeft: '10px', borderLeft: '1px solid #aaa', color: totalWeight < 20000 ? '#df5553' : '#161515' }}>Tổng trọng lượng còn lại: {formatWeightText(totalWeight)} Kg</Col>
              <Col style={{ marginLeft: '20px', paddingLeft: '10px', borderLeft: '1px solid #aaa' }}>Đã chọn: {selectedRecord.length}</Col>
              {selectedRecord.length > 0 && <CloseCircleFilled style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => set_selectedRecord([])} />}
            </Row>
          </Col>
          <Popover
            placement="bottomRight"
            content={<div>
              <Row style={{ width: '800px' }}>
                {columns.filter(item => !!item.titleConfig).map(item =>
                  <Col span={6}>
                    <Checkbox
                      disabled={!!item.fixed}
                      onChange={({ target }) => set_tableConfig(currentState => ({ ...currentState, [`${item.key}`]: target.checked }))}
                      checked={tableConfig[`${item.key}`]}>{item.titleConfig}</Checkbox>
                  </Col>
                )}
              </Row>
            </div>}
            trigger="click"
            open={openConfig}
            onOpenChange={handleOpenChange}
          ><SettingOutlined style={{ marginRight: '10px', marginLeft: '10px', fontSize: '20px', color: '#2c3782' }} />
          </Popover>
          <Col><Button ghost disabled={selectedRecord.length === 0} className={style['ghost-button']} onClick={onPressExportLabel}>Xuất nhãn <DownloadOutlined style={{ color: '#2c3782' }} /></Button></Col>
          <Col><Button className={style['add-button']} onClick={onPressAdd}>Thêm mới <PlusOutlined style={{ color: 'white' }} /></Button></Col>
          {/* <Col><Button className={style['add-button']} onClick={()=> onPressCreateTicket()}>Tạo chỉ thị xuất</Button></Col>
          <Col><Button className={style['add-button']} onClick={()=> onPressCreateTicketApprove()}>Tạo chỉ thị không cần duyệt</Button></Col> */}
        </Row>
        {totalWeight < 20000 && <div style={{ color: '#df5553', fontWeight: 'bold' }}>Tổng trọng lượng còn lại trong kho thấp</div>}
        <Table showSorterTooltip={false} onChange={handleChange} loading={loading} columns={filterCol}
          dataSource={data} className={style['table']} rowKey="id" pagination={{ position: ['none', 'none'], pageSize: pageSize }}
          scroll={{ x: sumBy(filterCol,'width') + 300 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => { detailRef.current.openModal(record) }, // click row
            };
          }}
        />
        <Row justify={'end'} className={style['pagination']}>
          <Pagination
            locale={{ items_per_page: '' }}
            pageSizeOptions={[5, 10, 20]}
            className={style["pagination-bar"]}
            pageSize={pageSize}
            hideOnSinglePage
            current={currentPage}
            onChange={(index) => onChangePage(index)}
            defaultCurrent={1}
            total={total} 
            showSizeChanger={false}
          />
          <Select value={pageSize} defaultValue={pageSize} options={configs.PageSizes} onChange={onChangePageSize} style={{ marginLeft: '10px' }} />
        </Row>
      </div>
      <ChiTietTonCuon ref={detailRef} reloadData={() => requestGetData(currentDataRequest)} />

      <TaoChiThiXuatKhoModal    
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        setIsApprove={setIsApprove}
        onClickSave={onClickSaveTicket}
        isPressCreateTicket={isPressCreateTicket}
        ref={null} reloadData={() => {}}
        isApprove={isApprove}/>
      {/* <LoadingModal loading = {loadingScreen} /> */}
    </div>
  );
};

export default DanhSachTonKho;



const getTag = (weight) => {
  if (Number(weight) < 100) {
    return <TagFilled style={{ color: '#f27e7c' }} />
  } else if (Number(weight) < 500) {
    return <TagFilled style={{ color: '#f6a447' }} />
  }
  return <div></div>
}

const configSample = {
  importDate: true,
  nsmvCoilId: true, 
  nsmvRollCoilId: false,
  steelGrade: true,
  steelType: false,
  size: true,
  temWeight: true,
  netWeight: false,
  providerName: true,
  producerCode: true,
  note: true,
  checked: true,
  status: true
}