import { Breadcrumb, Button, DatePicker, Row, Select, Column, Col, Input, Dropdown, Menu, Table, Tag, Space, Checkbox, Pagination, Flex, notification } from "antd";
import { Link } from "react-router-dom";
import { apis, keys, paths } from "../../../../constants";
import style from "./danhSachXuatKho.module.less";
import { ArrowDownOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, DownloadOutlined, FormOutlined, SearchOutlined } from "@ant-design/icons";
import { IconEraser } from "../../../../assets/svg";
import { useEffect, useState , useRef } from "react";
import { checkEmptyData, formatWeightText, localGet } from "../../../../helpers";
import dayjs from 'dayjs'
import AxiosService from "../../../../common/AxiosService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import configs from "../../../../constants/configs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { appLoading } from "../../../../ducks/slices/appSlice";
import ChiTietXuatKho from "./ChiTietXuatKho/ChiTietXuatKho";

const DanhSachXuatKho = () => {


  const [data, set_data] = useState([]);
  const [total, set_total] = useState(0);

  const [providerName, set_providerName] = useState('')
  const [coilId, set_coilId] = useState('')
  const [steelGrade, set_steelGrade] = useState('')
  const [importDate, set_importDate] = useState()
  const [steelType, set_steelType] = useState('')
  const [netWeight, set_netWeight] = useState('')
  const [size, set_size] = useState('')
  const [sizeWidth, set_sizeWidth] = useState('')
  const [sizeDepth, set_sizeDepth] = useState('')
  const [sizeWidthTo, set_sizeWidthTo] = useState('')
  const [sizeDepthTo, set_sizeDepthTo] = useState('')
  const [currentDataRequest, set_currentDataRequest] = useState({})
  const [currentPage, set_currentPage] = useState(1);
  const [workShift, set_workShift] = useState('');
  const [workTeam, set_workTeam] = useState('');
  const [user, set_user] = useState('');

  const [masterDataWorkShift, set_masterDataWorkShift] = useState([])
  const [masterDataWorkTeam, set_masterDataWorkTeam] = useState([])

  const [filterStatus, set_filterStatus] = useState(null)

  const [selectedRecord, set_selectedRecord] = useState([])

  const history = useHistory();
  const [loading, set_loading] = useState(true)
  const [pageSize, set_pageSize] = useState(10);
  const [totalWeight, set_totalWeight] = useState(10);
  const dispatch = useDispatch();
  const detailRef = useRef();

  const auth = useSelector(state => state.auth)
  const app = useSelector(state => state.app)
  const onPressClearFilter = () => {

  }
  useEffect(() => {
    initData()
  }, [auth.wareHouseName])

  const initData = () => {
    let dataRequest = {
      start: 0,
      length: pageSize,
      providerName: null,
      nsmvCoilId: null,
      steelGrade: null,
      steelType: null,
      sizeWidth: null,
      sizeDepth: null,
      netWeight: null,
      deliverDate: null,
      status: filterStatus,
      userWorkName: null,
      shiftWork: null,
      teamWork: null,
      start: 0,
      length: 10,
      sortRequest : {
        importDate : "desc",
        nsmvCoilId : "asc",
        steelGrade : null,
        steelType : null,
        sizeDepth : null,
        sizeWidth : null,
        netWeight : null,
        temWeight: null,
        providerName : null,
        deliverDate : null
    }
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
    // requestGetMasterData()
  }

  const onPressFilter = () => {
    let sizeRangeValidate = getSizeRangeValidate();
    set_currentPage(1)
    let dataRequest = {
      start: 0,
      length: pageSize,
      // draft: null,
      status: filterStatus,
      providerName: checkEmptyData(providerName),
      nsmvCoilId: checkEmptyData(coilId),
      steelGrade: checkEmptyData(steelGrade),
      steelType: checkEmptyData(steelType),
      sizeWidth: checkEmptyData(sizeRangeValidate.sizeWidth),
      sizeDepth: checkEmptyData(sizeRangeValidate.sizeDepth),
      sizeWidthTo: checkEmptyData(sizeRangeValidate.sizeWidthTo),
      sizeDepthTo: checkEmptyData(sizeRangeValidate.sizeDepthTo),
      netWeight: checkEmptyData(netWeight),
      deliverDate: !!checkEmptyData(importDate) ? dayjs(importDate).format('DD/MM/YYYY') : null,
      userWorkName: checkEmptyData(user),
      shiftWork: checkEmptyData(workShift),
      teamWork: checkEmptyData(workTeam),
      start: 0,
      length: 10,
      sortRequest : {
        importDate : "desc",
        nsmvCoilId : "asc",
        steelGrade : null,
        steelType : null,
        sizeDepth : null,
        sizeWidth : null,
        netWeight : null,
        temWeight: null,
        providerName : null,
        deliverDate : null
    }
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const requestGetData = (dataRequest) => {
    set_loading(true)
    AxiosService.post(apis.Url_Api_Post_LayDSXuatKho, dataRequest)
      .then(response => {
        let { data } = response;
        if (!!data && data.message === "OK") {
          if (dataRequest.start === 0) {
            set_total(data.totalRecords)
            set_totalWeight(data.totalWeight)
          }
          set_data(data.data)
        } else if (data.message === keys.api_message_EMPTY) {
          set_total(0)
          set_data([])
          set_totalWeight(0)
          notification.error({message: "Không có dữ liệu"})
        }
        set_loading(false)
      })
      .catch(err =>{ console.log(err);
        set_loading(false)
        notification.error({message: "Lỗi hệ thống"})
      })
  }

  const getSizeRangeValidate = () => {
    let requestSize = {
      sizeWidth:'',
      sizeDepth:'',
      sizeWidthTo:'',
      sizeDepthTo:'',
    }

    if(sizeDepth == '' && sizeDepthTo != ''){
      requestSize.sizeDepth = "";
      requestSize.sizeDepthTo = sizeDepthTo;
      // set_sizeDepth(sizeDepthTo)
      // set_sizeDepthTo('')
    }else if(sizeDepthTo == '' && sizeDepth != ''){
      requestSize.sizeDepth = sizeDepth;
    }else if(sizeDepthTo != '' && sizeDepth != ''){
      if(Number(sizeDepthTo) < Number(sizeDepth)){
        set_sizeDepth(sizeDepthTo)
        set_sizeDepthTo(sizeDepth)
        requestSize.sizeDepth = sizeDepthTo;
        requestSize.sizeDepthTo = sizeDepth;
      }else{
        requestSize.sizeDepth = sizeDepth;
        requestSize.sizeDepthTo = sizeDepthTo;
      }
    }else{
      //do nothing
    }

    if(sizeWidth == '' && sizeWidthTo != ''){
      requestSize.sizeWidth = "";
      requestSize.sizeWidthTo = sizeWidthTo;
      // set_sizeWidth(sizeWidthTo)
      // set_sizeWidthTo('')
    }else if(sizeWidthTo == '' && sizeWidth != ''){
      requestSize.sizeWidth = sizeWidth;
    }else if(sizeWidthTo != '' && sizeWidth != ''){
      if(Number(sizeWidthTo) < Number(sizeWidth)){
        set_sizeWidth(sizeWidthTo)
        set_sizeWidthTo(sizeWidth)
        requestSize.sizeWidth = sizeWidthTo;
        requestSize.sizeWidthTo = sizeWidth;
      }else{
        requestSize.sizeWidth = sizeWidth;
        requestSize.sizeWidthTo = sizeWidthTo;
      }
    }else{
      //do nothing
    }

    return requestSize;
  }

  const onChangePageSize = (val) => {
    set_pageSize(val);
    set_currentPage(1);
    let dataRequest = {
      ...currentDataRequest,
      start: 0,
      length: val,
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const onChangePage = (val) => {
    console.log('onchange page');
    set_currentPage(val)
    let dataRequest = {
      ...currentDataRequest,
      start: pageSize * (val - 1),
      length: pageSize * val,
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const reloadData = () => {
    requestGetData(currentDataRequest)
  }

  const onClickRow = (record, index) => {
    if (selectedRecord.some(item => item.id === record.id)) {
      set_selectedRecord(currentData => currentData.filter(item => item.id !== record.id))
    } else {
      set_selectedRecord(currentData => [...currentData, record])
    }

    detailRef.current.openModal(record)
  }

  const handleChangeFilterStatus = (e) => {
    set_filterStatus(e)
  }

  const onPressToCreateTicket = () => {
    history.push(paths.main_them_moi_xuat_kho)
  }



 
  const handleChange = (pagination, filters, sorter, extra) => {
    let newSortRequest;

    if (!Array.isArray(sorter)) {
        const { field, order } = sorter;
        const normalizedOrder = order === "ascend" ? "asc" : "desc";
        const orderValue = order ? normalizedOrder : null; 
        newSortRequest = { [field]: orderValue };
    } else {
        newSortRequest = sorter.reduce((acc, item) => {
            if (item.order) {
                const normalizedOrder = item.order === "ascend" ? "asc" : "desc";
                acc[item.field] = normalizedOrder;
            }
            return acc;
        }, {});
    }

    const currentSortRequest = { ...currentDataRequest.sortRequest };
    for (const key in currentSortRequest) {
        if (!(key in newSortRequest)) {
            newSortRequest[key] = null; 
        }
    }

    if (newSortRequest.size) {
        newSortRequest.sizeDepth = newSortRequest.size;
        newSortRequest.sizeWidth = newSortRequest.size;
    }

    const finalSortRequest = { ...newSortRequest };

    const newCurrentDataRequest = { ...currentDataRequest, sortRequest: finalSortRequest };
    console.log("newCurrentDataRequest" ,newCurrentDataRequest );
    set_currentDataRequest(newCurrentDataRequest);
    requestGetData(newCurrentDataRequest);
};
  

  const columns = [
    {
      title: 'Ngày nhập',
      dataIndex: 'importDate',
      key: 'importDate',
      width: '120px',
      // render: (text) => moment(text,"MM/DD/YYYY").format("DD/MM/YYYY"),
      defaultSortOrder : currentDataRequest?.sortRequest?.importDate === "asc" ? "ascend" : "descend",
      // sorter: {
      //   multiple: 1
      // },
      sorter : true
    },
    {
      title: 'Số cuộn',
      dataIndex: 'nsmvCoilId',
      key: 'nsmvCoilId',
      width: '200px',
      render: (value) => <div className={style["important-text"]}>{value}</div>,
      // defaultSortOrder : currentDataRequest?.sortRequest?.nsmvCoilId === "desc" ? "descend" : "ascend",
      // sorter: {
      //   multiple: 2
      // },
      sorter : true
    },

    {
      title: 'Mác thép',
      dataIndex: 'steelGrade',
      key: 'steelGrade',
      width: '150px',
      render: (totalRoll, item) => totalRoll,
      // sorter: {
      //   multiple: 3
      // },
      sorter : true
    },

    {
      title: 'Chủng loại',
      dataIndex: 'steelType',
      key: 'steelType',
      width: '150px',
      render: (totalRoll, item) => totalRoll,
      // sorter: {
      //   multiple: 4
      // },
      sorter : true
    },
    {
      title: 'Dày x rộng(mm)',
      dataIndex: 'size',
      key: 'size',
      width: '150px',
      render: (totalRoll, item) => (item.sizeDepth.toString().includes(".") ? item.sizeDepth : item.sizeDepth + ".0") + " × " + (item.sizeWidth.toString().includes(".") ? item.sizeWidth : item.sizeWidth + ".0"),
      // sorter: {
      //   multiple: 5
      // },
      sorter : true
    },
    {
      title: 'Trọng lượng(kg)',
      dataIndex: 'netWeight',
      key: 'netWeight',
      width: '250px',
      render: (netWeight) => formatWeightText(netWeight),
      sorter : true
      // sorter: {
      //   multiple: 6
      // },
   
    },
    {
      title: 'Trọng lượng thực tế(kg)',
      dataIndex: 'netWeight',
      key: 'netWeight',
      width: '250px',
      render: (totalNetWeight) => formatWeightText(totalNetWeight),
      sorter : true
      // sorter : true
      // sorter: {
      //   multiple: 7
      // },
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'providerName',
      key: 'providerName',
      width: '250px',
      // sorter: {
      //   multiple: 8
      // },
      sorter : true
    },
    {
      title: 'Ngày Xuất',
      dataIndex: 'deliverDate',
      key: 'deliverDate',
      width: '250px',
      render: (text) => <div>{text}</div>,
      // defaultSortOrder : currentDataRequest.sortStable.Array
      // sorter: {
      //   multiple: 9
      // },
      sorter : true
    },
    {
      title: 'Người thực hiện',
      dataIndex: 'userWorkName',
      width: '200px',
      key: 'userWorkName',
     
    },
   
    {
      title: 'Tổ',
      dataIndex: 'teamWorkName',
      key: 'teamWorkName',
    },
    {
      title: 'Ca',
      dataIndex: 'shiftWorkName',
      key: 'shiftWorkName',
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '150px',
      render: (value) => getStatusLabel(value),
    }
  ];

  const getStatusLabel = (status) => {

    switch (status) {
      case '2':
        return <div style={{ backgroundColor: '#14af14', color: '#fff', width: 'fit-content', padding: '5px', borderRadius: '8px' }}>Chờ duyệt</div>;
      case '1':
        return <div style={{ backgroundColor: 'green', color: '#fff', width: 'fit-content', padding: '5px', borderRadius: '8px' }}>Hoàn thành</div>
      case '3':
        return <div style={{ backgroundColor: 'green', color: '#fff', width: 'fit-content', padding: '5px', borderRadius: '8px' }}>Đã duyệt/Chờ xuất</div>;
      case '-1':
        return <div style={{ backgroundColor: 'green', color: '#fff', width: 'fit-content', padding: '5px', borderRadius: '8px' }}>Hủy</div>;
      default:
        return <div style={{ backgroundColor: 'green', color: '#fff', width: 'fit-content', padding: '5px', borderRadius: '8px' }}>Chờ duyệt</div>;
    }
  }


  const onClickClearData = () => {
    set_importDate('')
    set_providerName('')
    set_coilId('')
    set_steelGrade('')
    set_importDate('')
    set_steelType('')
    set_netWeight('')
    set_sizeWidth('')
    set_sizeDepth('')
    set_sizeWidthTo('')
    set_sizeDepthTo('')
    set_workShift('')
    set_workTeam('')
    set_filterStatus('2')
    set_user('')
  }

  const doExportData = async () => {
    let bodyExport = {...currentDataRequest, length: null, "start": 0, wareHouseName: auth.wareHouseName}
    console.log("bodyExport",bodyExport);
    dispatch(appLoading(true))
    try {
      fetch(apis.Url_Api_Post_ExportDanhSachXuatKho, {
        method: 'POST',
        body: JSON.stringify(bodyExport),
        headers: {
          "Content-Type": "application/json",
          Authorization: await localGet(keys.access_token),
        },
      })
        .then(response => response.blob())
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res]));

          const link = document.createElement('a');
          link.href = url;
          link.download = "DS_XuatKho_" + moment().format('YYYY-MM-DD') + ".xlsx";

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
          dispatch(appLoading(false))
        }).catch(error => {
          dispatch(appLoading(false))
          notification.error({message: "Không có dữ liệu"})
      });
    } catch (error) {
      dispatch(appLoading(false))
      notification.error({message: "Không có dữ liệu"})
    }

  }


  return (
    <div className={style["main-container"]}>
      <Breadcrumb items={[{
        title: 'Xuất kho',
      },
      {
        title: 'Danh sách xuất kho',
      }]} />

      <div className={style['main']}>
        <Row gutter={[10, 20]} align={'middle'}>
          <Col span={3} className={style['filter-title']}>Nhà cung cấp</Col>
          <Col span={5}><Input className={style['filter-input']} value={providerName} onChange={({ target }) => set_providerName(target.value)} /></Col>

          <Col span={3} className={style['filter-title']}>Số cuộn</Col>
          <Col span={5}><Input className={style['filter-input']} value={coilId} onChange={({ target }) => set_coilId(target.value)} /></Col>

          <Col span={3} className={style['filter-title']}>Mác thép</Col>
          <Col span={5}><Input className={style['filter-input']} value={steelGrade} onChange={({ target }) => set_steelGrade(target.value)} /></Col>

          <Col span={3} className={style['filter-title']}>Chủng loại</Col>
          <Col span={5}><Select
            className={style['filter-select']}
            onChange={set_steelType}
            defaultValue={''}
            value={steelType}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={app.steelTypeMaster}
          /></Col>

          <Col span={3} className={style['filter-title']}>Trọng lượng</Col>
          <Col span={5}><Input className={style['filter-input']} value={netWeight} onChange={({ target }) => set_netWeight(target.value.replace(/[^0-9]/g, ""))} /></Col>

          <Col span={3} className={style['filter-title']}>Dày × rộng</Col>
          <Col span={5}><Flex align="center">
              <Input className={style['filter-input']} value={sizeDepth} onChange={({ target }) => set_sizeDepth(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidth} onChange={({ target }) => set_sizeWidth(target.value.replace(/[^0-9.]/g, ""))} />
              </Flex>

              <Flex align="center" justify='space-around'>
              <ArrowDownOutlined />
              <ArrowDownOutlined />
              </Flex>

              <Flex align="center">
              <Input className={style['filter-input']} value={sizeDepthTo} onChange={({ target }) => set_sizeDepthTo(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidthTo} onChange={({ target }) => set_sizeWidthTo(target.value.replace(/[^0-9.]/g, ""))} />
              </Flex>
              
          </Col>

          <Col span={3} className={style['filter-title']}>Ngày Xuất</Col>
          <Col span={5}><DatePicker format={"DD/MM/YYYY"} className={style['filter-input']} value={importDate} onChange={(date, dateString) => { set_importDate(date) }} /></Col>

          <Col span={3} className={style['filter-title']}>Trạng thái</Col>
          <Col span={5}><Select
            className={style['filter-select']}
            onChange={handleChangeFilterStatus}
            defaultValue={filterStatus}
            value={filterStatus}
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={[
              { value: null, label: 'Tất cả' },
              { value: '3', label: 'Chờ xuất' },
              { value: '2', label: 'Chờ phê duyệt' },
              { value: '1', label: 'Hoàn thành' },
              { value: '-1', label: 'Hủy' },
            ]}
          /></Col>

          <Col span={3} className={style['filter-title']}>Người thực hiện</Col>
          <Col span={5}><Input className={style['filter-input']} value={user} onChange={({ target }) => set_user(target.value)} /></Col>

          <Col span={3} className={style['filter-title']}>Ca</Col>
          <Col span={5}><Select
            className={style['filter-select']}
            onChange={set_workShift}
            defaultValue={''}
            value={workShift}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={app.shiftMaster}
          /></Col>

          <Col span={3} className={style['filter-title']}>Tổ</Col>
          <Col span={5}><Select
            className={style['filter-select']}
            onChange={set_workTeam}
            defaultValue={''}
            value={workTeam}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={app.teamMaster}
          /></Col>
        </Row>
        <Row gutter={30} align={'middle'} justify={'center'} style={{ marginTop: '20px' }}>
          <Col><Button onClick={onPressFilter} icon={<SearchOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tìm kiếm</Button></Col>
          <Col><Button onClick={onClickClearData} icon={<IconEraser style={{ height: '16px', width: '16px' }} />} className={style['filter-button']}>Xóa</Button></Col>
          <Col><Button onClick={doExportData} icon={<DownloadOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tải xuống</Button></Col>
          <Col><Button onClick={onPressToCreateTicket} className={style['filter-button']}>Tạo phiếu mới</Button></Col>
        </Row>

        <Row gutter={60} align={'middle'} justify={'space-between'} style={{ marginTop: '20px' }}>
          <Col>Tổng số: {total}</Col>
          <Col flex={'auto'}>Tổng trọng lượng thực tế: {formatWeightText(totalWeight)} Kg</Col>
          {/* <Col ><Button className={style['filter-button']} style={{ backgroundColor: '#7ED9FF', color: '#000' }}>Tạo chỉ thi</Button></Col> */}
        </Row>
        
        <Table showSorterTooltip={false} onChange={handleChange} loading = {loading} columns={columns} dataSource={data} className={style['table']} pagination={{ position: ['none', 'none'], pageSize: pageSize }}
          rowKey={record => record.rollId + record.orderNo} rowClassName={style['table-row']} onRow={(record, rowIndex) => {
            return {
              onClick: (event) => { onClickRow(record, rowIndex) }, // click row
            };
          }} />
        <Row justify={'end'} className={style['pagination']}>
          <Pagination
            locale={{ items_per_page: '' }}
            pageSizeOptions={[1, 5, 10, 20]}
            className={style["pagination-bar"]}
            pageSize={pageSize}
            hideOnSinglePage
            current={currentPage}
            onChange={(index) => onChangePage(index)}
            defaultCurrent={1}
            total={total}
            showSizeChanger={false}

          />
          <Select value={pageSize} defaultValue={pageSize} options={configs.PageSizes} onChange={onChangePageSize} style={{ marginLeft: '10px' }} pagination={{ position: ['none', 'none'], pageSize: pageSize }} />
        </Row>

      </div>
      <ChiTietXuatKho ref={detailRef} reloadData={() => requestGetData(currentDataRequest)} />
    </div>
  );


};



export default DanhSachXuatKho;