import React, { useCallback, useEffect, useRef, useState } from 'react';
import style from './YeuCauSXBangXe.module.less';
import { Col, Button, Row, notification, Input, Breadcrumb, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { produce } from 'immer';
import { debounce, isEqual, uniqBy, uniqueId } from 'lodash';
import { localGet, localRemove, localSet } from '../../../../../helpers';
import { apis, keys, paths } from '../../../../../constants';
import { appLoading } from '../../../../../ducks/slices/appSlice';
import AxiosService from '../../../../../common/AxiosService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import BlockYeuCauV2 from '../BlockYeuCauV2/BlockYeuCauV2';
import { buildAddRequest, buildExcelRequest } from '../utils';
import ThemMoiNhapKho from '../ThemMoiNhapKho';
import { scrollIntoViewWithOffset, useHandleScroll } from './useScroll';

const initialStandard = {
    // thicknessMin: '',
    id: 'standard_0',
    orderCode: '',
    thicknessProduct: '',
    sizeWidthTape: '',
    typeProduct: '',
    stripNumber: '',
    totalWidth: '',
    //weightCal: '',
    note: '',
};

const initialRoll = {
    id: 'roll_0',
    rollId: '',
    importDate: '',
    weight: '',
    steelType: '',
    steelGrade: '',
    ts: '',
    thickWidth: '',
};

const initialBlock = {
    id: 'block_0',
    workOrderDate: '',

    //roll common
    weightAvg: 0,
    sizeThickness: '',
    sizeWidth: '',
    sizeWidthReal: '',
    positionRoll1: '',
    positionRoll2: '',
    md: '',
    catDau: '',

    //via
    sizeWidthVia: 0,
    totalVia: 0,
    ratioTrash: 0,
    mass: 0,
    errorVia: false,

    listRoll: [initialRoll],
    listStandard: [initialStandard],
};

const YeuCauSXBangXeV2 = () => {
    const [state, setState] = useState([initialBlock]);
    const stateRef = useRef();
    const { wareHouseName } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();
    const [modal, contextHolder] = Modal.useModal();

    //list rollID/ngày lệnh đã có trong danh sách
    const rollIdSelected = state.reduce((prev, next) => prev.concat(next.listRoll.map(i => i.rollId).filter(i => !!i)), []);
    // const lsNgayLenh = state.reduce((prev, next) => prev.concat(next.workOrderDate), []);

    const [validation, set_validation] = useState([]);

    const [productNo, setProductNo] = useState('');
    const productNoRef = useRef();

    const addRoll = idBlock => {
        setState(
            produce(state => {
                var iBlock = state.findIndex(item => item.id == idBlock);
                state[iBlock].listRoll.push({ ...initialRoll, id: uniqueId('roll_') });
            }),
        );
    };
    const deleteRoll = tupleID => {
        const [idBlock, idCuon] = tupleID.split('-');
        setState(
            produce(state => {
                var iBlock = state.findIndex(item => item.id == idBlock);
                var iRoll = state[iBlock].listRoll.findIndex(i => i.id == idCuon);
                if (iRoll > 0) state[iBlock].listRoll.splice(iRoll, 1);
            }),
        );
    };
    //prettier-ignore
    const editRoll = 
        (tupleID, field, keyboardType = 'none') => event => {
            let val = ['string' ,'number'].includes(typeof event) ? event : event.target?.value
            if (keyboardType == 'numeric') {
                val = val.replace(/[^0-9.]/g, '');
            }
            console.log("Field", field, " Value", val)
            const [idBlock, idCuon] = tupleID.split('-');
            setState(produce(state => {
                var iBlock = state.findIndex(item => item.id == idBlock);
                var iRoll = state[iBlock].listRoll.findIndex(i => i.id == idCuon);
                state[iBlock].listRoll[iRoll][field] = val;
                // lstBlock.find(item => item.id == idCuon)[field] = val;
            }));
        }

    //prettier-ignore
    const editStandard = useCallback(
        (tupleID, field, keyboardType = 'none') => event => {
            let val = ['string' ,'number'].includes(typeof event) ? event : event.target?.value
            if (keyboardType == 'numeric') {
                val = val.replace(/[^0-9.]/g, '');
            }
            const [idBlock, idStandard] = tupleID.split('-');
            setState(produce(state => {
                var iBlock = state.findIndex(item => item.id == idBlock);
                var iStand = state[iBlock].listStandard.findIndex(i => i.id == idStandard);
                state[iBlock].listStandard[iStand][field] = val;
                // lstBlock.find(item => item.id == idCuon)[field] = val;
            }));
        },[]);

    const addStandard = idBlock => {
        setState(
            produce(state => {
                var iBlock = state.findIndex(item => item.id == idBlock);
                state[iBlock].listStandard.push({ ...initialStandard, id: uniqueId('standard_') });
            }),
        );
    };
    const deleteStandard = tupleID => {
        const [idBlock, idStandard] = tupleID.split('-');
        setState(
            produce(state => {
                var iBlock = state.findIndex(item => item.id == idBlock);
                var iStand = state[iBlock].listStandard.findIndex(i => i.id == idStandard);
                if (iStand > 0) state[iBlock].listStandard.splice(iStand, 1);
            }),
        );
    };
    const addBlock = () => {
        setState(prev => prev.concat({ ...initialBlock, id: uniqueId('block_') }));
        scrollToBottom();
    };
    const deleteBlock = idBlock => {
        setState(prev => (prev.length > 1 ? prev.filter(i => i.id != idBlock) : prev));
    };

    //xoá toàn bộ cuộn trong 1 block(trường hợp thay đổi dày/rộng)
    const deleteAllRoll = idBlock => {
        setState(
            produce(state => {
                var iBlock = state.findIndex(item => item.id == idBlock);
                state[iBlock].listRoll = [initialRoll];
            }),
        );
    };

    //prettier-ignore
    const editBlock = useCallback((id, field, keyboardType = 'none') => event => {
        let val = ['string', 'number', 'boolean'].includes(typeof event) ? event : event.target?.value;
        if (keyboardType == 'numeric') {
            val = val.replace(/[^0-9.]/g, '');
        }
        setState(
            produce(state => {
                var iBlock = state.findIndex(item => item.id == id);
                state[iBlock][field] = val;
            }),
        );
    },[]);

    const validateForm = () => {
        var validArr = Array(state.length).fill(true);
        let errorFieldId = '';
        for (const [index, block] of state.entries()) {
            if (!block.workOrderDate) {
                validArr[index] = false;
                errorFieldId = errorFieldId || `block_${index}-workOrderDate`;
            }
            if (!block.sizeWidth || !block.sizeWidthReal || !block.sizeThickness) {
                validArr[index] = false;
                errorFieldId = errorFieldId || `block_${index}-sizeWidth`;
            }
            var iRoll = block.listRoll.findIndex(roll => !roll.rollId);
            if (iRoll > -1) {
                validArr[index] = false;
                errorFieldId = errorFieldId || `block_${index}-roll_${iRoll}`;
            }
            if (uniqBy(block.listRoll, 'thickWidth').length > 1) {
                validArr[index] = false;
                errorFieldId = errorFieldId || `block_${index}-rollTable`;
            }
            var iStand = block.listStandard.findIndex(stand => !stand.thicknessProduct || !stand.typeProduct || !stand.sizeWidthTape || !stand.stripNumber);
            if (iStand > -1) {
                validArr[index] = false;
                errorFieldId = errorFieldId || `block_${index}-standard_${iStand}`;
            }
            if (block.errorVia) {
                errorFieldId = errorFieldId || `block_${index}-widthVia`;
            }
        }
        var msgError = '';
        if (errorFieldId.includes('workOrderDate') || errorFieldId.includes('sizeWidth') || errorFieldId.includes('standard')) {
            msgError = 'Chưa nhập đủ thông số';
        } else if (errorFieldId.includes('roll_')) {
            msgError = 'Chưa chọn cuộn để xẻ';
        } else if (errorFieldId.includes('rollTable')) {
            msgError = 'Cuộn phải có cùng kích thước dày/rộng';
        } else if (errorFieldId.includes('widthVia')) {
            msgError = 'Thông số chưa hợp lệ. Vui lòng nhập lại';
        }
        set_validation(validArr);

        if (errorFieldId) {
            console.log('Error field', errorFieldId);
            notification.error({ message: msgError });
            var element = document.getElementById(errorFieldId);
            if (element) {
                scrollIntoViewWithOffset(errorFieldId);
            }
            return false;
        } else return true;
    };

    const resetForm = () => {
        console.log('From reset!');
        localRemove(keys.yeucau_bangxe);
        localRemove(keys.malenh_bangxe);
        setState([initialBlock]);
        setProductNo('');
    };

    const confirmResetForm = () => {
        modal.confirm({
            title: 'Xác nhận xoá',
            icon: <ExclamationCircleOutlined />,
            content: 'Toàn bộ dữ liệu đã nhập sẽ bị xoá. Tiếp tục?',
            okText: 'OK',
            okType: 'danger',
            onOk: () => resetForm(),
            cancelText: 'Đóng',
        });
    };

    const submitRequest = async (isPrint = false) => {
        if (!productNo) {
            notification.error({ message: 'Chưa nhập mã lệnh cho yêu cầu' });
            return;
        }
        console.log('Before state', state);
        const transformState = buildAddRequest(state);
        console.log('After state: ', transformState);

        if (!validateForm()) return;
        console.log('Form is valid!');

        const dataSend = {
            id: null,
            productNo,
            workSplitDate: moment().format('DD/MM/YYYY'),
            lstBlockParent: transformState,
        };
        console.log('Data', dataSend);
        dispatch(appLoading(true));
        try {
            const { data } = await AxiosService.post(apis.Url_Api_Post_YeuCauBangXe, dataSend);
            //console.log('Data response', data);
            if (data.status == 200) {
                resetForm();
                notification.success({ message: 'Tạo yêu cầu thành công' });
                debounce(() => history.push(paths.main_ds_yeu_cau_xe_bang), 200)();
            } else {
                notification.error({ message: data.message });
            }
        } catch (e) {
            console.log('Error băng xẻ', e);
            notification.error({ message: 'Tạo yêu cầu thất bại' });
        } finally {
            dispatch(appLoading(false));
        }
    };
    const submitAndPrintRequest = async () => {
        if (!productNo) {
            notification.error({ message: 'Chưa nhập mã lệnh cho yêu cầu' });
            return;
        }
        console.log('Before state', state);
        const transformState = buildAddRequest(state);
        console.log('After state: ', transformState);

        if (!validateForm()) return;
        console.log('Form is valid!');
        const dataSend = {
            id: null,
            productNo,
            workSplitDate: moment().format('DD/MM/YYYY'),
            lstBlockParent: transformState,
        };
        console.log('Data', dataSend);
        dispatch(appLoading(true));
        try {
            const { data } = await AxiosService.post(apis.Url_Api_Post_YeuCauBangXe, dataSend);
            //console.log('Data response', data);
            if (data.status == 200) {
                const printData = { ...dataSend, lstBlockParent: buildExcelRequest(dataSend.lstBlockParent) };
                fetch(apis.Url_Api_Post_InXeBang, {
                    method: 'POST',
                    body: JSON.stringify(printData),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localGet(keys.access_token),
                    },
                })
                    .then(response => response.blob())
                    .then(res => {
                        const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
                        const iframe = document.createElement('iframe');
                        iframe.style.display = 'none'; // Hide the iframe
                        iframe.src = url;
                        iframe.onload = function () {
                            dispatch(appLoading(false));
                            iframe.contentWindow.print();
                            resetForm();
                            notification.success({ message: 'Tạo yêu cầu thành công' });
                            debounce(() => history.push(paths.main_ds_yeu_cau_xe_bang), 100)();
                        };
                        document.body.appendChild(iframe);
                    })
                    .catch(error => {
                        dispatch(appLoading(false));
                        console.log('Error print Yêu cầu', error);
                        notification.error({ message: 'Có lỗi khi in yêu cầu' });
                    });
            } else {
                dispatch(appLoading(false));
                notification.error({ message: data.message });
            }
        } catch (e) {
            console.log('Error băng xẻ', e);
            notification.error({ message: 'Tạo yêu cầu thất bại' });
            dispatch(appLoading(false));
        }
    };

    //lưu trữ form vào local storage để user không phải edit lại khi chuyển trang
    // NOTE: nhớ xoá local storage này sau khi đã submit form !!!
    useEffect(() => {
        stateRef.current = state;
    }, [state]);
    useEffect(() => {
        productNoRef.current = productNo;
    }, [productNo]);

    useEffect(() => {
        //lưu thêm 1 số trường filter (sửa trong BlockYeuCauV2)
        const savedForm = localGet(keys.yeucau_bangxe);
        const savedProductNo = localGet(keys.malenh_bangxe);
        console.log('Saved from: ', savedForm);
        console.log('Saved product no: ', savedProductNo);
        if (!!savedForm) {
            setState(savedForm);
        }
        if (!!savedProductNo) {
            setProductNo(savedProductNo);
        }

        return () => {
            var prevState = stateRef.current;
            console.log(prevState[0]);
            if (prevState.length == 1 && isEqual(prevState[0], initialBlock)) {
                //không update giá trị default
            } else {
                console.log('Saved state', stateRef.current);
                localSet(keys.yeucau_bangxe, stateRef.current);
                localSet(keys.malenh_bangxe, productNoRef.current);
            }
        };
    }, []);

    const topViewVisible = useHandleScroll();

    const bottomRef = useRef(null);
    const scrollToBottom = () => {
        //scroll to bottom khi add block mới
        //cần phải sửa lại sau
        debounce(() => bottomRef.current?.scrollIntoView({ behavior: 'smooth' }), 200)();
    };

    return (
        <div className={style['main-container']} id="main-layout">
            <Breadcrumb
                items={[
                    {
                        title: 'Nhập kho',
                    },
                    {
                        title: 'Thêm mới',
                    },
                ]}
            />
            <ThemMoiNhapKho />
            {wareHouseName == 'X' && (
                <div className={style['main']}>
                    <Row>
                        <Col span={6}>
                            <h3 style={{ color: 'black' }}>Yêu cầu sản xuất băng xẻ</h3>
                        </Col>
                        <Col span={1}>
                            <h4 style={{ color: 'black' }}>Mã lệnh</h4>
                        </Col>
                        <Col span={4} className={style['block-title']}>
                            <Input className={cn(style['block-input'])} value={productNo} onChange={({ target }) => setProductNo(target.value)} />
                        </Col>
                        <Col span={13}>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '10px' }}>
                                <Button className={cn(style['filter-button'], style['button'])} style={{ height: '34px' }}>
                                    Lưu & In
                                </Button>
                                <Button className={cn(style['ghost-button'], style['button'])} style={{ height: '34px' }} onClick={submitRequest}>
                                    Lưu yêu cầu
                                </Button>
                                <Button className={cn(style['filter-button'], style['delete-button'])} style={{ height: '34px' }} onClick={confirmResetForm}>
                                    Xoá form nhập
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    {state.map((item, index, arrState) => (
                        <div key={item.id}>
                            <BlockYeuCauV2
                                // ref={refCuon[refIndex]}
                                // errors={errState}
                                data={item}
                                addBlock={addBlock}
                                editBlock={editBlock}
                                deleteBlock={deleteBlock}
                                addRoll={addRoll}
                                editRoll={editRoll}
                                deleteRoll={deleteRoll}
                                deleteAllRoll={deleteAllRoll}
                                addStandard={addStandard}
                                editStandard={editStandard}
                                deleteStandard={deleteStandard}
                                rollIdSelected={rollIdSelected}
                                blockIndex={index}
                                error={validation[index] === false}
                            />
                        </div>
                    ))}
                </div>
            )}

            <div id="bottom-div" ref={bottomRef}></div>
            {contextHolder}
            {topViewVisible && (
                <div
                    className={style['sub']}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        backgroundColor: 'white',
                        padding: '15px 20px',
                        paddingRight: '35px',
                        zIndex: 999,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    <Row>
                        <Col span={6}>
                            <h3 style={{ color: 'black' }}>Yêu cầu sản xuất băng xẻ</h3>
                        </Col>
                        <Col span={1}>
                            <h4 style={{ color: 'black' }}>Mã lệnh</h4>
                        </Col>
                        <Col span={4} className={style['block-title']}>
                            <Input className={cn(style['block-input'])} value={productNo} onChange={({ target }) => setProductNo(target.value)} />
                        </Col>
                        <Col span={13}>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '10px' }}>
                                <Button className={cn(style['filter-button'], style['button'])} style={{ height: '34px' }} onClick={submitAndPrintRequest}>
                                    Lưu & In
                                </Button>
                                <Button className={cn(style['ghost-button'], style['button'])} style={{ height: '34px' }} onClick={() => submitRequest()}>
                                    Lưu yêu cầu
                                </Button>
                                <Button className={cn(style['filter-button'], style['delete-button'])} style={{ height: '34px' }} onClick={confirmResetForm}>
                                    Xoá form nhập
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default YeuCauSXBangXeV2;
