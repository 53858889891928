import {
  Button,
  Col,
  DatePicker,
  Flex,
  Input,
  Modal,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import style from "./taoChiThiXuatKhoModal.module.less";
import { MISA_NAME_ACRONYM } from "../../../constants/utils";
import dayjs from "dayjs";
import AxiosService from "../../../common/AxiosService";
import { apis } from "../../../constants";
import { sumBy } from "lodash";

const currentDate = dayjs().format("DD/MM/YYYY");

const TaoChiThiXuatKhoModal = forwardRef(({onClickSave, onClickSavePartExport, splitMachine}, ref) => {

  const [isVisible, setVisible] = useState(false);
  const [mode, setMode] = useState('approval');

  const [misaNameAcronym, setMisaNameAcronym] = useState("SR");
  const [deliverDate, setDeliverDate] = useState(currentDate);
  const [amountExport, setAmount] = useState('');

  //dùng để fetch trọng lượng xuất 1 phần của cuộn tương ứng
  const [rollExport,set_rollExport] = useState(null);

  const [totalDeliver,set_totalDeliver] = useState(-1);

  const handleOk = () => {
    setVisible(false)
    // setIsApprove(false)
  };

  const closeModal = () => {
    setMisaNameAcronym("SR");
    setDeliverDate(currentDate);
    setAmount("")
    setVisible(false)
    set_rollExport(null)
    // set_totalDeliver(null)
  };

  const handleSave = () => {
    if(mode == 'part_export') {
      if(amountExport && rollExport) {
        if(Number(amountExport) <= 0) {
          notification.error({message: "Trọng lượng phải lơn hơn 0"})
        }
        else if (Number(amountExport) > Number(rollExport.netWeight)) {
          notification.error({message: "Vượt quá trọng lượng còn lại"})
        }
        else {
          onClickSavePartExport(misaNameAcronym, deliverDate, amountExport);
          closeModal()
        }
      }
    }
    else {
      onClickSave(misaNameAcronym,deliverDate, mode == 'finish')
      closeModal()
    };
  };

  const handleDeliverDate = (date , dateString) =>{
    setDeliverDate(dateString)
  }
  const openModePartExport = async (selectRoll)=> {
    setVisible(true);
    setMode('part_export');
    setDeliverDate(currentDate);
    set_rollExport(selectRoll)
    // try {
    //   const {data} = await AxiosService.post(apis.Url_Api_Post_DanhSachXuat1Phan,{idGoods: selectRoll.id});
    //   if(data.status === 200) {
    //     console.log("Data, come", data.data)
    //     set_totalDeliver(sumBy(data.data,item => item.weightDeliver))
    //   }
    //   else notification.error({message: "Không lấy được thông tin cuộn"})
    // }
    // catch(e){
    //   console.log("Error fetch roll", e)
    // }

  }
  useImperativeHandle(ref, ()=>({
    openModeApproval() {
      setVisible(true);
      setMode('approval');
      setDeliverDate(currentDate)
    },
    openModeFinish() {
      setVisible(true);
      setMode('finish');
      setDeliverDate(currentDate);
    },
   openModePartExport
  }))

  const title = (
    <h1 className={style["title"]}>
      Xuất kho thực tế
    </h1>
  );
  return (
    <Modal
      title={title}
      closeIcon={false}
      open={isVisible}
      onOk={handleOk}
      onCancel={closeModal}
      className={style["modal-container"]}
      width={"550px"}
      footer={[]}
    >
      <div className={style["main"]}>
        <Row gutter={[10, 20]} align={"middle"} justify={"space-between"} style={{ marginTop: "20px" }}>
          {/* <Col span={4} className={style["filter-title"]}>
            Chủng loại
          </Col> */}
          <Col span={10} >
            <label  className={style['filter-title']} >Mục đích sử dụng</label>
            <Select
              className={style["filter-select"]}
              style={{marginTop : "10px"}}
              onChange={setMisaNameAcronym}
              defaultValue={"SR"}
              value={misaNameAcronym}
              showSearch
              optionFilterProp="label"
              dropdownStyle={{
                borderWidth: 1,
                borderColor: " rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
              }}
              options={MISA_NAME_ACRONYM}
            />
          </Col>
          {(mode == 'finish' || mode == 'part_export') && (
          <Col span={10}>
          <label  className={style['filter-title']} >Ngày xuất kho thực tế</label>
          <DatePicker format={"DD/MM/YYYY"}  className={style['filter-input']}   style={{marginTop : "10px"}} onChange={handleDeliverDate}
              value={deliverDate !== '' ? dayjs(deliverDate, 'DD/MM/YYYY') : undefined} 
          />
          </Col>
          )}
          {mode == 'part_export' && (
          <Col span={10}>
          <label  className={style['filter-title']} >Trọng lượng xuất kho</label>
          <Input className={style['filter-input']} style={{marginTop : "10px"}} value={amountExport} onChange={({target}) => setAmount(target.value.replace(/[^0-9.]/g, ""))}/>
          {!!rollExport && <h5 style={{color:'red', marginTop:'5px'}}>Trọng lượng xuất còn lại: {rollExport.netWeight} kg</h5>}
          </Col>
          )}
        </Row>
        <Row
          align={"middle"}
          style={{ marginTop: "20px" }}
          gutter={[20]}
          justify={"end"}
        >
          <Col>
            <Button key="back" onClick={closeModal}>
              Đóng
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={handleSave}>
              Lưu
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
});

export default TaoChiThiXuatKhoModal;
