import { createSlice } from "@reduxjs/toolkit";
import { localSet } from "../../helpers";
import { keys } from "../../constants";

const initState = {
  isOk: undefined,
  message: undefined,
  user: undefined,
  token: undefined, 
  isLoading: false,
  user_data: undefined,
  wareHouseName: "C",
  listWarehouse: []
};

const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    doLogin(state) {
      return { ...state, isOk: undefined, message: undefined, isLoading: true };
    },
    doLogout() {
      localSet(keys.access_token, undefined);
      localSet(keys.user_data, "");
      localSet(keys.key_loginTime, "");
      return initState ;
    },

    authSuccess(state, {payload}) {
      let listWarehouse = []
      if(payload.user_data?.roleRollCoil == 'C') listWarehouse.push({value: "C", title: "Kho tôn cuộn"})
      if(payload.user_data?.roleSplitTape == 'X') listWarehouse.push({value: "X", title: "Kho xẻ"})
      return {...payload, listWarehouse};
    },

    authFail(state, action) {
      return action.payload;
    },

    resetAuthMessage(state) {
      return { ...state, isOk: undefined, message: undefined };
    },

    authRestore(state, {payload}) {
      let listWarehouse = []
      if(payload.user_data?.roleRollCoil == 'C') listWarehouse.push({value: "C", title: "Kho tôn cuộn"})
      if(payload.user_data?.roleSplitTape == 'X') listWarehouse.push({value: "X", title: "Kho xẻ"})
      return { ...state , ...payload, listWarehouse};
    },

    changeWarehouse(state, {payload}) {
      return {...state, wareHouseName: payload}
    }
  },
});

export const { doLogin, doLogout, authSuccess, authFail, resetAuthMessage, authRestore , changeWarehouse} =
  authSlice.actions;

export default authSlice.reducer;
