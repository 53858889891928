const auth = "/auth";
const main = "/main";
const main_nhap_kho = main + "/nhap_kho";
const main_xuat_kho = main + "/xuat_kho";
const main_kiem_ke = main + "/kiem_ke";
const main_bao_cao = main + "/bao_cao";
const main_quan_ly = main + "/quan_ly";
const root = "/";

const paths = {
  root: "/",

  auth: auth,
  dang_nhap: auth + "/dang-nhap", // Đăng nhập
  quen_mat_khau: auth + "/quen-mat-khau", // Đăng nhập
  khoi_phuc_mat_khau: auth + "/recovery-password", // Đăng nhập
  khoi_phuc_mat_khau_wtoken: auth + "/recovery-password/:token", // Đăng nhập


  main: main,
  trang_chu: main + "/trang-chu",

  main_tong_quan: main + "/tong_quan", 
  main_danh_sach_ton_kho: main + "/danh_sach_ton_kho", 
  main_nhap_kho: main + "/nhap_kho",
  main_them_moi_nhap_kho: main_nhap_kho + "/them_moi",
  main_danh_sach_nhap_kho: main_nhap_kho + "/danh_sach",
  main_chi_tiet_chi_thi_nhap_kho: main_nhap_kho + "/chi_tiet_chi_thi",
  main_ds_yeu_cau_xe_bang: main_nhap_kho + "/ds_yeu_cau_xe_bang",

  main_xuat_kho: main + "/xuat_kho",
  main_danh_sach_chi_thi_xuat_kho: main_xuat_kho + "/danh-sach-chi-thi",
  main_danh_sach_xuat_kho: main_xuat_kho + "/danh-sach-xuat-kho",
  main_them_moi_xuat_kho: main_xuat_kho + "/tao-moi-chi-thi",

  main_kiem_ke: main + "/kiem_ke",
  main_them_moi_chi_thi_kiem_ke: main_kiem_ke + "/tao_chi_thi",
  main_danh_sach_chi_thi_kiem_ke: main_kiem_ke + "/chi_thi_kiem_ke",

  main_bao_cao: main + "/bao_cao",
  main_bao_cao_thong_ke: main_bao_cao + "/thong_ke",
  main_bao_cao_xuat_file: main_bao_cao + "/xuat_file",

  main_chuyen_kho: main + "/chuyen_kho",
  main_bao_cao: main + "/bao_cao",
  main_cai_dat: main + "/cai_dat", 


  quan_ly_danh_muc: main + "/quan-ly-danh-muc", // Quản lý danh mục (quản lý hệ thống)
  quan_ly_tiep_don: main + "/quan-ly-tiep-don", // Quản lý tiếp đón
  quan_ly_vien_phi: main + "/quan-ly-vien-phi",
  quan_ly_kham_benh: main + "/quan-ly-kham-benh",
  quan_ly_chan_doan_hinh_anh_ris: main + "/quan-ly-chan-doan-hinh-anh-ris",
  quan_ly_bhyt: main + "/quan-ly-bhyt",
  quan_ly_duoc_ngoai_tru: main + "/quan-ly-duoc-ngoai-tru",
  quan_ly_xep_hang_qms: main + "/quan-ly-xep-hang-qms",
  
  main_quan_ly: main_quan_ly,
  quy_chuan_bang_xe: main_quan_ly + "/quy_chuan_bang_xe",
  quy_chuan_bang_xe_special: main_quan_ly + "/quy_chuan_bang_xe_dac_biet"
};

export default paths;
