import { languageKeys, languages, Translate } from "../i18n";
import { en, jp, vi } from "../i18n/languages";

const configs = {
    // Domain: 'https://quanlykho-api.nsmv.com.vn/api/', //release
    // Domain_S: 'https://quanlykho-api.nsmv.com.vn/', //release

   
    // Domain_S: 'http://172.18.101.106:8286/stock_backend/',
    // Domain: 'http://172.18.101.106:8286/stock_backend/api/',
    // Domain: 'http://172.18.101.106:8286/stock_backend_001/api/',
    // Domain_S: 'http://172.18.101.106:8286/stock_backend_001/',
    // Domain_S: ' http://172.18.102.66:8080/',//test 8
    // Domain: ' http://172.18.102.66:8080/api/', //test 8

    // Domain_S: ' http://172.18.102.86:8080/',//test Đạt
    // Domain: ' http://172.18.102.86:8080/api/', //test Đạt

    // Domain: 'https://test-quanlykho-api.nsmv.com.vn/api/', //release-test trên site khách
    // Domain_S: 'https://test-quanlykho-api.nsmv.com.vn/', //release-test trên site khách

    Domain_S: '/stock_backend/',//test release
    Domain: '/stock_backend/api/', //test release
    
    //local trên máy Đạt
    Local_Dat: 'http://172.18.102.86:8080/api/',
    Local_Tuan: 'http://172.18.102.59:8080/api/',
    Local_Host: 'http://localhost:8080/api/',


    Language_Dropdown_Keys: [
        {
            value: languages.tieng_anh,
            label: en.Language_label
        },
        {
            value: languages.tieng_nhat_api,
            label: jp.Language_label
        },
        {
            value: languages.tieng_viet,
            label: vi.Language_label
        }
    ],

    PageSizes : [
        {
            value: 1,
            label: `1`
            // label: `5 /${Translate(languageKeys.Common_Page)}`
        },
        {
            value: 5,
            label: `5`
            // label: `5 /${Translate(languageKeys.Common_Page)}`
        },
        {
            value: 10,
            label: `10`
        },
        {
            value: 20,
            label: `20`
        }
      ]

    
};

export default configs;
