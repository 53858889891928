import { Breadcrumb, Button, DatePicker, Row, Select, Column, Col, Input, Table, Flex, Pagination, Modal, notification, Checkbox } from "antd";
import { apis, keys, paths } from "../../../../constants";
import style from "./danhSachChiThiNhap.module.less";
import { ArrowDownOutlined, CheckOutlined, DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, FormOutlined, PlusOutlined, SearchOutlined, TagFilled } from "@ant-design/icons";
import { IconEraser } from "../../../../assets/svg";
import { useEffect, useRef } from "react";
import AxiosService from "../../../../common/AxiosService";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { HLog, checkEmptyData, formatWeightText } from "../../../../helpers";
import dayjs from 'dayjs'
import { useSelector } from "react-redux";
import ChiTietChiThiNhap from "./ChiTietChiThiNhap/ChiTietChiThiNhap";
import XetDuyetChiThiNhap from "./XetDuyetChiThiNhap/XetDuyetChiThiNhap";
import moment from "moment";

const DanhSachChiThiNhap = () => {
  const [data, set_data] = useState([])
  const [total, set_total] = useState(0)
  const [modal, contextHolder] = Modal.useModal();
  const [providerName, set_providerName] = useState('')
  const [coilId, set_coilId] = useState('')
  const [steelGrade, set_steelGrade] = useState('')
  const [importDate, set_importDate] = useState('')
  const [steelType, set_steelType] = useState('')
  const [temWeight, set_temWeight] = useState('')
  const [sizeWidth, set_sizeWidth] = useState('')
  const [sizeDepth, set_sizeDepth] = useState('')
  const [sizeWidthTo, set_sizeWidthTo] = useState('')
  const [sizeDepthTo, set_sizeDepthTo] = useState('')
  const [currentDataRequest, set_currentDataRequest] = useState({})
  const [currentPage, set_currentPage] = useState(1);
  const [selectedRecord, set_selectedRecord] = useState([])
  const [loading, set_loading] = useState(true);
  const history = useHistory();
  const detailRef = useRef();
  const confirmRef = useRef();

  const [pageSize, set_pageSize] = useState(10);
  const app = useSelector(state => state.app)
  const auth = useSelector(state => state.auth)

  const disabledButton = {
    opacity : 0.5
  }

  const disableButton = selectedRecord.length < 1
  useEffect(() => {
    //reset data khi đổi kho
    set_selectedRecord([])
    onPressClear()
    initData()
  }, [auth.wareHouseName])

  const initData = () => {
    let dataRequest = {
      start: 0,
      length: pageSize,
      draft: "1",
      status: null,
      providerName: null,
      steelType: null,
      rollNsmvId: null,
      steelGrade: null,
      importDate: null,
      temWeight: null,
      sizeWidth: null,
      sizeDepth: null,
      saveInStock: "0"
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const onPressFilter = () => {
    let sizeRangeValidate = getSizeRangeValidate();
    set_currentPage(1)
    let dataRequest = {
      start: 0,
      length: pageSize,
      draft: "1",
      status: null,
      providerName: providerName,
      steelType: checkEmptyData(steelType) ,
      rollNsmvId:  checkEmptyData(coilId),
      steelGrade:  checkEmptyData(steelGrade),
      importDate: !!checkEmptyData(importDate) ? dayjs(importDate).format('DD/MM/YYYY') : null,
      temWeight: !!checkEmptyData(temWeight) ? Number(temWeight) : null,
      "sizeWidth":checkEmptyData(sizeRangeValidate.sizeWidth) ,
      "sizeDepth":checkEmptyData(sizeRangeValidate.sizeDepth) ,
      "sizeWidthTo":checkEmptyData(sizeRangeValidate.sizeWidthTo) ,
      "sizeDepthTo":checkEmptyData(sizeRangeValidate.sizeDepthTo) ,
      saveInStock: "0"
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const getSizeRangeValidate = () => {
    let requestSize = {
      sizeWidth:'',
      sizeDepth:'',
      sizeWidthTo:'',
      sizeDepthTo:'',
    }

    if(sizeDepth == '' && sizeDepthTo != ''){
      requestSize.sizeDepth = "";
      requestSize.sizeDepthTo = sizeDepthTo;
      // set_sizeDepth(sizeDepthTo)
      // set_sizeDepthTo('')
    }else if(sizeDepthTo == '' && sizeDepth != ''){
      requestSize.sizeDepth = sizeDepth;
    }else if(sizeDepthTo != '' && sizeDepth != ''){
      if(Number(sizeDepthTo) < Number(sizeDepth)){
        set_sizeDepth(sizeDepthTo)
        set_sizeDepthTo(sizeDepth)
        requestSize.sizeDepth = sizeDepthTo;
        requestSize.sizeDepthTo = sizeDepth;
      }else{
        requestSize.sizeDepth = sizeDepth;
        requestSize.sizeDepthTo = sizeDepthTo;
      }
    }else{
      //do nothing
    }

    if(sizeWidth == '' && sizeWidthTo != ''){
      requestSize.sizeWidth = "";
      requestSize.sizeWidthTo = sizeWidthTo;
      // set_sizeWidth(sizeWidthTo)
      // set_sizeWidthTo('')
    }else if(sizeWidthTo == '' && sizeWidth != ''){
      requestSize.sizeWidth = sizeWidth;
    }else if(sizeWidthTo != '' && sizeWidth != ''){
      if(Number(sizeWidthTo) < Number(sizeWidth)){
        set_sizeWidth(sizeWidthTo)
        set_sizeWidthTo(sizeWidth)
        requestSize.sizeWidth = sizeWidthTo;
        requestSize.sizeWidthTo = sizeWidth;
      }else{
        requestSize.sizeWidth = sizeWidth;
        requestSize.sizeWidthTo = sizeWidthTo;
      }
    }else{
      //do nothing
    }

    return requestSize;
  }

  const requestGetData = (data) => {
    set_loading(true)
    AxiosService.post(apis.Url_Api_Post_LayDanhSachNhapKho, data)
      .then(response => {
        console.log(response);
        let { data } = response;
        if (!!data && data.message === "OK") {
          set_total(data.totalRecords)
          set_data(data.data)
        }else if (data.message === keys.api_message_EMPTY) {
          set_total(0)
          set_data([])
        }
        set_loading(false)
      })
      .catch(err =>{ HLog(err);
        set_loading(false)})
  }

  const onChangePageSize = (val) => {
    set_pageSize(val);
    set_currentPage(1);
    let dataRequest = {
      ...currentDataRequest,
      start: 0,
      length: val,
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
    
  }

  const onChangePage = (val) => {
    
    set_currentPage(val)
    let dataRequest = {
      ...currentDataRequest,
      start: pageSize * (val - 1),
      length: pageSize * val
     
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
    
  }

  const onPressClear = () => {
    set_importDate('')
    set_providerName('')
    set_coilId('')
    set_steelGrade('')
    set_importDate('')
    set_steelType('')
    set_temWeight('')
    set_sizeWidth('')
    set_sizeDepth('')
    set_sizeWidthTo('')
    set_sizeDepthTo('')
  }

  const onPressAdd = () => {
    history.push(paths.main_them_moi_nhap_kho);
  }

  const onSelectRow = (record) => {
    if (selectedRecord.some(item => item.id === record.id)) {
      set_selectedRecord(currentData => currentData.filter(item => item.id !== record.id))
    } else {
      set_selectedRecord(currentData => [...currentData, record])
    }
  }


  const selectAllCurrentList = ({ target }) => {
    if (target.checked) {
      set_selectedRecord(currentData => [...currentData, ...data.filter((record) => !currentData.map(item => item.id).includes(record.id) && (record.accessRequestDraft == "0" || !record.accessRequestDraft) )])
    } else {
      set_selectedRecord(currentData => currentData.filter(record => !data.map(item => item.id).includes(record.id)))
    }
  }
  const columns = [
    {
      title:  !!auth.user_data && !!auth.user_data.isAccessImport ? <Checkbox onClick={selectAllCurrentList} checked={!data.filter(i => i.accessRequestDraft === '0').some(record => !selectedRecord.map(item => item.id).includes(record.id)) && selectedRecord.length > 0} /> : "",
      dataIndex: 'checked',
      key: 'checked',
      width: '35px',
      render: (text, record) =>  record.accessRequestDraft == "1" ?
        <CheckOutlined style={{color: '#33b65f', fontSize: 20}}/> :
        !!auth.user_data && !!auth.user_data.isAccessImport ? <Checkbox onClick={(e) => {e.stopPropagation()}} checked={selectedRecord.some(item => item.id === record.id)} onChange={(e) => {e.stopPropagation(); onSelectRow(record)}}/>: <div/>,
      fixed: 'left'
    },
  {
    title: 'Ngày nhập',
    dataIndex: 'importDate',
    key: 'importDate',
    width: '115px',
    fixed:'left',
    // render: (value) => moment(value).format('DD/MM/YYYY')
  },
  {
    title: auth.wareHouseName == 'X' ? "Số dải" : 'Số cuộn',
    dataIndex: 'nsmvCoilId',
    key: 'nsmvCoilId',
    width: '120px',
    fixed:'left',
    render: (value) => <div className={style["important-text"]}>{value}</div>
  },
  {
    title: 'Mác thép',
    dataIndex: 'steelGrade',
    width: '100px',
    key: 'steelGrade'
  },
  {
    title: 'Chủng loại',
    width: '120px',
    dataIndex: 'steelType',
    key: 'steelType'
  },
  {
    title: 'Dày x rộng(mm)',
    dataIndex: 'size',
    key: 'size',
    width: '150px',
    render: (size, item) =>(item.sizeDepth.toString().includes(".") ? item.sizeDepth : item.sizeDepth + ".0") + " × " + (item.sizeWidth.toString().includes(".") ? item.sizeWidth : item.sizeWidth + ".0")
  },
  {
    title: 'Trọng lượng(kg)',
    dataIndex: 'temWeight',
    key: 'temWeight',
    width: '150px',
    render: (value) => formatWeightText(value)
  },

  {
    title: 'Nhà cung cấp',
    dataIndex: 'providerName',
    width: '150px',
    key: 'providerName'
  },
  {
    title: 'Nhà sản xuất',
    dataIndex: 'producerCode',
    width: '150px',
    key: 'producerCode'
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    width: '150px',
    key: 'note'
  },
  {
    title: '',
    dataIndex: 'value',
    width: '140px',
    key: 'value',
    fixed: 'right',
    render: (value, data) => <Flex align='center'>
      <Button style={{}} onClick={(e) => {e.stopPropagation(); detailRef.current.openModal(data) }}>Sửa</Button>
      <Button type = 'primary' danger style={{marginLeft: '10px'}} onClick={(e) => {e.stopPropagation(); onRequestDelele(data)}}>Xóa</Button>
      </Flex>
  },

];

const onRequestDelele = (data) => {
  modal.confirm({
    title: 'Xác nhận xóa',
    icon: <ExclamationCircleOutlined />,
    content: 'Bạn chắc chắn muốn xóa sản phẩm này?',
    okText: 'Xóa',
    okType: 'danger',
    onOk : () => onsendRequestCancel(data),
    cancelText: 'Đóng',
  });

}

const onsendRequestCancel = (item) => {
  let dataRequest = {
    "id" : item.id
}
  AxiosService.post(apis.Url_Api_Post_HuyChiThiNhapKho, dataRequest)
  .then(res => {
    let { data } = res;
    HLog(res);
    if (!!data && data.message === "OK") {
      notification.success({message: "Xóa thành công"})
    } else {
      notification.error({message: data.message})
    }
    onPressFilter()
  })
  .catch(e => notification.error({message: e.message}))
}


  return (
    <div className={style["main-container"]}>
      <Breadcrumb items={[{
        title: 'Nhập kho',
      },{
        title: 'Danh sách nhập kho',
      }]} />

      <div className={style['main']}>
        <Row gutter={[10, 20]} align={'middle'}>
          <Col span={2} className={style['filter-title']}>Nhà cung cấp</Col>
          <Col span={4}><Input className={style['filter-input']} value={providerName} onChange={({ target }) => set_providerName(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>{auth.wareHouseName == 'X' ? 'Số dải' : 'Số cuộn'}</Col>
          <Col span={4}><Input className={style['filter-input']} value={coilId} onChange={({ target }) => set_coilId(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>Mác thép</Col>
          <Col span={4}><Input className={style['filter-input']} value={steelGrade} onChange={({ target }) => set_steelGrade(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>Ngày nhập</Col>
          <Col span={4}><DatePicker format={"DD/MM/YYYY"}  className={style['filter-input']} value={importDate} onChange={(date, dateString) => {set_importDate(date)}  } /></Col>
          <Col span={2} className={style['filter-title']}>Chủng loại</Col>
          <Col span={4}><Select
            className={style['filter-select']}
            onChange={set_steelType}
            defaultValue={''}
            value={steelType}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={app.steelTypeMaster}
          /></Col>
          <Col span={2} className={style['filter-title']}>Dày × rộng</Col>
          <Col span={4}>
          <Flex align="center">
              <Input className={style['filter-input']} value={sizeDepth} onChange={({ target }) => set_sizeDepth(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidth} onChange={({ target }) => set_sizeWidth(target.value.replace(/[^0-9.]/g, ""))} />
              </Flex>

              <Flex align="center" justify='space-around'>
              <ArrowDownOutlined />
              <ArrowDownOutlined />
              </Flex>

              <Flex align="center">
              <Input className={style['filter-input']} value={sizeDepthTo} onChange={({ target }) => set_sizeDepthTo(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidthTo} onChange={({ target }) => set_sizeWidthTo(target.value.replace(/[^0-9.]/g, ""))} />
              </Flex>
          </Col>
          <Col span={2} className={style['filter-title']}>Trọng lượng</Col>
          <Col span={4}><Input className={style['filter-input']} value={temWeight} onChange={({ target }) => set_temWeight(target.value.replace(/[^0-9.]/g, ""))} /></Col>
        </Row>

        <Row gutter={60} align={'middle'} justify={'center'} style={{ marginTop: '20px' }}>
          <Col ><Button onClick={onPressFilter} icon={<SearchOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tìm kiếm</Button></Col>
          <Col ><Button onClick={onPressClear} icon={<IconEraser style={{ height: '16px', width: '16px' }} />} className={style['filter-button']}>Xóa</Button></Col>
          {/* <Col ><Button icon={<DownloadOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tải xuống</Button></Col> */}
        </Row>

        <Row style={{ marginTop: '40px' }} align={'middle'} justify={'space-between'}>
          <Col flex={'auto'}>
            <Row align={'middle'}>
              <Col>Tổng số: {total}</Col>      
            </Row>
          </Col>

          { !!auth.user_data && !!auth.user_data.isAccessImport && <Col style={{ marginRight: '10px'}}>
            <Button className={style['add-button']}  
            disabled = {disableButton}
            style={disableButton ? disabledButton : ""}
          onClick={(e) => {
            confirmRef.current.openModal(selectedRecord) 
            }} >Duyệt</Button></Col>}
          <Col><Button className={style['add-button']} onClick={onPressAdd}>Thêm mới <PlusOutlined style={{ color: 'white' }} /></Button></Col>
        </Row>
        <Table scroll={{ x: '100%' }} loading = {loading} columns={columns} dataSource={data} className={style['table']} rowKey="id" pagination={{ position: ['none', 'none'], pageSize: pageSize }}/>
        <Row justify={'end'} className={style['pagination']}>
          <Pagination
            locale={{ items_per_page: '' }}
            pageSizeOptions={[5, 10, 20]}
            className={style["pagination-bar"]}
            pageSize={pageSize}
            hideOnSinglePage
            current={currentPage}
            onChange={(index) => onChangePage(index)}
            defaultCurrent={1}
            total={total}
            showSizeChanger = {false}
          />
          <Select value={pageSize} defaultValue={pageSize} options={pageSizes} onChange={onChangePageSize} style={{ marginLeft: '10px' }} />
        </Row>
      </div>
      <ChiTietChiThiNhap ref = {detailRef} reloadData = {() => requestGetData(currentDataRequest)}/>
      <XetDuyetChiThiNhap ref = {confirmRef} reloadData = {() => requestGetData(currentDataRequest)}/>

      {contextHolder}
    </div>
  );
};

export default DanhSachChiThiNhap;



const getTag = (weight) => {
  if(Number(weight) < 100){
    return <TagFilled style={{color: '#f27e7c'}}/>
  }else if (Number(weight) < 500){
    return <TagFilled style={{color: '#f6a447'}}/>
  }
  return <div></div>
}

const pageSizes = [
  {
      value: 5,
      label: `5`
      // label: `5 /${Translate(languageKeys.Common_Page)}`
  },
  {
      value: 10,
      label: `10`
  },
  {
      value: 20,
      label: `20`
  }
]