import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Button, Input, notification } from 'antd';
import { apis } from '../../../../constants';
import style from './danhSachYeuCauXeBang.module.less';
import AxiosService from '../../../../common/AxiosService';

import { useDispatch } from 'react-redux';
import { appLoading } from '../../../../ducks/slices/appSlice';
import { Row, Col, DatePicker } from 'antd';
import dayjs from 'dayjs';
import TableYeuCauV2 from './TableYeuCauV2';

const DanhSachYeuCauXeBang = () => {
    const dispatch = useDispatch();
    const [lstYeucau, set_lstYeuCau] = useState([]);
    const [searchDate, set_searchDate] = useState('');
    const [searchProductNo, set_serachNo] = useState('');

    const [filterYeuCau, set_filter] = useState([]);
    const onPressFilter = () => {
        set_filter(lstYeucau.filter(i => !searchProductNo || i.productNo.toLowerCase().includes(searchProductNo.toLowerCase())).filter(i => i.workSplitDate.includes(searchDate)));
    };
    const onResetFilter = () => {
        set_filter(lstYeucau);
        set_searchDate('');
        set_serachNo('');
    };

    const fetchData = useCallback(async () => {
        dispatch(appLoading(true));
        try {
            const { data } = await AxiosService.post(apis.Url_Api_Post_DanhSachXeBang, { productNo: null, workSplitDate: null });
            if (data.status == 200) {
                set_lstYeuCau(data.data);
                set_filter(data.data);
            }
        } catch (e) {
            console.log('Error happened', e);
            notification.error({ message: 'Lấy danh sách thất bại' });
        } finally {
            dispatch(appLoading(false));
        }
    }, []);
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={style['main-container']}>
            <Breadcrumb
                items={[
                    {
                        title: 'Nhập kho',
                    },
                    {
                        title: 'Danh sách yêu cầu xẻ băng',
                    },
                ]}
            />
            <div className={style['main']}>
                <Row style={{ marginTop: '25px' }}>
                    <Col span={2} className={style['filter-title']}>
                        Mã lệnh
                    </Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={searchProductNo} onChange={({ target }) => set_serachNo(target.value)} />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        Ngày nhập
                    </Col>
                    <Col span={4}>
                        <DatePicker
                            format={'DD/MM/YYYY'}
                            className={style['filter-input']}
                            placeholder=""
                            value={searchDate !== '' ? dayjs(searchDate, 'DD/MM/YYYY') : undefined}
                            onChange={(date, dateString) => set_searchDate(dateString)}
                        />
                    </Col>
                    <Col span={8}></Col>
                    <Col span={4}>
                        <div style={{ flexDirection: 'row-reverse', display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Button className={style['filter-button']} onClick={onResetFilter}>
                                Xoá bộ lọc
                            </Button>
                            <Button className={style['filter-button']} onClick={onPressFilter}>
                                Tìm yêu cầu
                            </Button>
                        </div>
                    </Col>
                </Row>
                {filterYeuCau.map(item => (
                    <TableYeuCauV2 data={item} key={item.id} refetchData={fetchData} />
                ))}
            </div>
        </div>
    );
};

export default DanhSachYeuCauXeBang;
