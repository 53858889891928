import { useEffect, useState } from 'react';

const HIDE_THRESHOLD = 360;
const SCROLL_OFFSET = 200;

/**
 * handle hiển thị view khi scroll xuống
 */
export const useHandleScroll = () => {
    const [showTopView, setShowTopView] = useState(false);
    useEffect(() => {
        const layout = document.getElementById('main-layout');
        const handleScroll = () => {
            const scrollPosition = layout.scrollTop;
            //console.log('ScrollPos: ', scrollPosition);
            if (scrollPosition > HIDE_THRESHOLD) {
                setShowTopView(true);
            } else {
                setShowTopView(false);
            }
        };

        layout.addEventListener('scroll', handleScroll);
        // Clean up the event listener when the component unmounts
        return () => {
            layout.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return showTopView;
};

/**
 * Scroll đến 1 element với offset
 */
export const scrollIntoViewWithOffset = selector => {
    const layout = document.getElementById('main-layout');
    const scrollDes = layout.scrollTop + document.getElementById(selector).getBoundingClientRect().top - SCROLL_OFFSET;
    console.log('Scroll Des: ', scrollDes);
    layout.scrollTo({
        behavior: 'smooth',
        top: scrollDes,
    });
};
